import React from "react";
import "./IssuePopover.scss";
import InteractiveTooltip from "../InteractiveTooltip";
import Icon from "../Icon/Icon";
import {Button, Tooltip} from 'gd-react';
import getFormat from "../../services/formatter";
import GridDuck from 'gridduck';

let issue_types = [
    {
        field: 'disconnected',
        noNumber: true,
        icon: 'FaUnlink',
        label: 'Disconnected',
        color: '#e43535'
    },
    {
        field: 'intermittent',
        noNumber: true,
        icon: 'FaUnlink',
        label: 'Intermittent connection',
        color: 'orange'
    },
    {
        field: 'lowBattery',
        noNumber: true,
        icon: 'MdBattery20',
        label: 'low battery',
        color: 'orange'
    },
    {
        field: 'lowSignal',
        noNumber: true,
        icon: 'MdSignalCellular1Bar',
        label: 'low signal',
        color: 'orange'
    },
    {
        field: 'issues',
        icon: 'FaUnlink',
        label: 'disconnected',
        color: '#e43535'
    },
    {
        field: 'intermittentAssets',
        icon: 'FaUnlink',
        label: 'intermittent connection',
        color: 'orange'
    },
    {
        field: 'lowBatteryAssets',
        icon: 'MdBattery20',
        label: 'low battery',
        color: 'orange'
    },
    {
        field: 'lowSignalAssets',
        icon: 'MdSignalCellular1Bar',
        label: 'low signal',
        color: 'orange'
    }
]

class IssuePopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            metadata: null,
            openDisconnectedDrawer: false
        };
        this.getData = this.getData.bind(this);
        // this.getMetadata = this.getMetadata.bind(this);
    }

    async componentDidMount() {
        if (!this.props.item) {
            await this.getData();
        }
    }

    async getData() {
        let account = await GridDuck.getAccount({id: GridDuck.userId});
        let siteGroup = await GridDuck.getSiteGroup({id: 'all_' + GridDuck.userId + '_' + account.organisationId});
        this.setState({item: siteGroup});
    }

    manageIssues(e, item) {
        e.preventDefault();
        e.stopPropagation();
        let isAllGroup = item?.id.indexOf('all') !== -1;
        console.log(item, ' : item');
        if (this.props.updateDisconnectedDrawer) this.props.updateDisconnectedDrawer({
            openDisconnectedDrawer: true,
            filterId: !isAllGroup ? item?.id : null,
            filterType: !isAllGroup ? item?.objectTypeName : null,
            filterItem: !isAllGroup ? item : null
        })
    }

    render() {
        let self = this;
        let item = this.props.item || this.state.item;
        let trimmedName = item?.name ? item?.name.substring(0, 30) + (item?.name?.length > 30 ? '...' : '') : '';
        let disconnected = item && (issue_types.find(it => item['issues']) || item.disconnected);
        let total = item ? parseInt(item.assetsWithIssues) : 0;
        let showIcon = item && total;
        if (this.props.onlyIf?.length) {
            showIcon = this.props.onlyIf.find(oi => item[oi]);
        }
        if (this.props.header) {
            showIcon = true;
        }
        let color = '#808080';
        if (total) {
            color = disconnected ? '#e43536' : '#ffa500';
        }
        return (
            showIcon ? <InteractiveTooltip key={"interactivetooltip-" + item?.id}
                // onOpen={this.getMetadata}
                                           title={
                                               <div className={'issue-popover filter-menu-container'} onClick={(e) => {
                                                   e.preventDefault();
                                                   e.stopPropagation();
                                               }}>
                                                   <div className={'menu-header'}>
                                                       {trimmedName} Device Issues
                                                   </div>
                                                   {item && item.assetsWithIssues && total ?
                                                       <div className={'issues-context'}>
                                                           <div className={'row issue-row title-row'}>
                                                               <div className={'issue-popover-icon'}><Icon
                                                                   icon={'FaPlug'}
                                                                   size={'12'}
                                                                   color={'grey'}/>
                                                               </div>
                                                               <p className={'issue-popover-item-text'}><span
                                                                   style={{color: 'grey'}}
                                                                   className={'fat'}>{getFormat('comma', true)(item.assetsWithIssues)} / {getFormat('comma', true)(item.appliances)}</span> devices
                                                                   have issues
                                                               </p>
                                                           </div>
                                                           <Tooltip
                                                               label={Math.round((item.assetsWithIssues / item.appliances) * 100) + '% have issues'}>
                                                               <div className={'issue-percentage'}>
                                                                   <div className={'issue-percentage-bar'}
                                                                        style={{width: ((item.assetsWithIssues / item.appliances) * 100) + '%'}}/>
                                                               </div>
                                                           </Tooltip>
                                                       </div> : null}
                                                   {(total && item) ? issue_types.map(it => item[it.field] ?
                                                       <div className={'row issue-row'} key={"row-issue-row"}>
                                                           <div className={'issue-popover-icon'}><Icon icon={it.icon}
                                                                                                       size={'12'}
                                                                                                       color={it.color}/>
                                                           </div>
                                                           <p className={'issue-popover-item-text'}><span
                                                               style={{color: it.color + ' !important'}}
                                                               className={'fat'}>{!it.noNumber ? getFormat('comma')(item[it.field]) : null}</span> {it.label}
                                                           </p>
                                                       </div> : null) : <div className={'no-notifications'}>
                                                       <Icon icon={'FaThumbsUp'} size={40} color={'gd-grey'}/>
                                                       <div style={{marginTop: '10px'}}>No device issues</div>
                                                   </div>}
                                                   <div className={'row reverse'}>
                                                       <Button style={{
                                                           height: '20px',
                                                           fontSize: '11px',
                                                           lineHeight: '20px',
                                                           marginTop: '15px'
                                                       }} label={'Manage issues'}
                                                               onClick={(e) => this.manageIssues(e, item)}/>
                                                   </div>
                                                   {/*<div className={'row reverse'}*/}
                                                   {/*     style={{marginTop: '5px', alignItem: 'center'}}>*/}
                                                   {/*    <a style={{*/}
                                                   {/*        fontSize: '11px',*/}
                                                   {/*        color: 'grey',*/}
                                                   {/*        borderColor: 'transparent',*/}
                                                   {/*        paddingRight: '5px'*/}
                                                   {/*    }} className={'link'}>*/}
                                                   {/*        <div style={{marginRight: '5px', display: 'inline'}}><Icon*/}
                                                   {/*            icon={'FaEyeSlash'} size={'11'}/></div>*/}
                                                   {/*        Ignore issues</a>*/}
                                                   {/*</div>*/}
                                               </div>}>
                <div className={'issue-icon-wrapper ' + (this.props.header ? 'header-icon' : '')}
                     onClick={(e) => this.manageIssues(e, item)}
                     style={{
                         borderColor: 'transparent',
                         backgroundColor: this.props.header ? color + '1a' : "transparent"
                     }}>
                    <Icon icon={'FaExclamationCircle'} size={this.props.iconSize} color={color}/> {total ? <div
                    style={{color: color,}} className={'issue-number-text'}>{getFormat('comma')(total)}</div> : null}
                </div>
                {/*{this.state.openDisconnectedDrawer ?*/}
                {/*    <DisconnectedDrawer*/}
                {/*        open={this.state.openDisconnectedDrawer}*/}
                {/*        onClose={() => self.setState({openDisconnectedDrawer: false})}/> : ''}*/}
            </InteractiveTooltip> : null
        )
    }
}

export default IssuePopover;
