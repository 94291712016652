import React from "react";
import {Dropdown} from "gd-react";

const months = [
    {title: 'January', days: 31},
    {title: 'February', days: 28},
    {title: 'March', days: 31},
    {title: 'April', days: 30},
    {title: 'May', days: 31},
    {title: 'June', days: 30},
    {title: 'July', days: 31},
    {title: 'August', days: 31},
    {title: 'September', days: 30},
    {title: 'October', days: 31},
    {title: 'November', days: 30},
    {title: 'December', days: 31},
].map((month, i) => {
    return {
        ...month,
        id: i + 1,
        value: i + 1
    }
});

function getMonthAndDay(dayOfYear) {
    const daysInMonths = months.map(m => m.days);

    let month = 0;

    while (dayOfYear > daysInMonths[month]) {
        dayOfYear -= daysInMonths[month];
        month++;
    }

    return {month: month + 1, day: dayOfYear}; // month + 1 to adjust for 0-based indexing
}

function getDaysFromMonth(month) {
    let totalDays = 1;
    for (let i = 1; i < month; i++) {
        totalDays += Number(months[i - 1].days);
    }
    return totalDays;
}

const getOrdinalSuffix = function (n) {
    const s = ["th", "st", "nd", "rd"],
        v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}


const positions = {
    monthly: Array.from({length: 31}, (v, i) => ({
        id: i + 1,
        title: getOrdinalSuffix(i + 1),
        value: i + 1
    })),
    weekly: [
        {id: 1, title: 'Monday', value: 1},
        {id: 2, title: 'Tuesday', value: 2},
        {id: 3, title: 'Wednesday', value: 3},
        {id: 4, title: 'Thursday', value: 4},
        {id: 5, title: 'Friday', value: 5},
        {id: 6, title: 'Saturday', value: 6},
        {id: 7, title: 'Sunday', value: 7}
    ]
}

class BillingCyclePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        let currentMonth = months.find(_m => _m.id === getMonthAndDay(this.props.billingCycleStartDay).month);

        return (<div style={{marginTop: '15px'}} className="row">
            <div style={{width: '150px', marginRight: '10px'}}>
                <Dropdown
                    title={'Frequency'}
                    label={'Frequency'}
                    disabled={this.props.disabled}
                    value={this.props.billingCycleType}
                    fixeditems={[
                        {id: 1, title: 'Weekly', value: 'weekly'},
                        {id: 2, title: 'Monthly', value: 'monthly'},
                        {id: 3, title: 'Quarterly', value: 'quarterly'},
                        {id: 4, title: 'Annually', value: 'annually'}
                    ]}
                    onChange={(e) => {
                        this.props.onBillingCycleTypeChange(e.target.value);
                    }}/>
            </div>
            {this.props.billingCycleType !== "quarterly" && this.props.billingCycleType !== 'annually' &&
                <div style={{width: '150px'}}>
                    <Dropdown
                        title={'Repeats on...'}
                        label={'Repeats on...'}
                        disabled={this.props.disabled}
                        value={this.props.billingCycleStartDay}
                        fixeditems={positions[this.props.billingCycleType]}
                        onChange={(e) => {
                            this.props.onBillingCycleStartDayChange(e.target.value);
                        }}/>
                </div>}
            {this.props.billingCycleType === 'annually' && <div style={{width: '100%'}}>
                <label>Repeats on...</label>
                <div className="row">
                    <div style={{width: '180px'}} className="dd-no-right">
                        <Dropdown
                            nolabel={'true'}
                            disabled={this.props.disabled}
                            value={getMonthAndDay(this.props.billingCycleStartDay).month}
                            fixeditems={months}
                            onChange={(e) => {
                                let totalDays = getDaysFromMonth(e.target.value);
                                this.props.onBillingCycleStartDayChange(totalDays);
                            }}/>
                    </div>
                    <div style={{width: '100px'}} className={'dd-no-left'}>
                        <Dropdown
                            nolabel={'true'}
                            disabled={this.props.disabled}
                            value={getMonthAndDay(this.props.billingCycleStartDay).day}
                            fixeditems={positions['monthly'].filter(m => m.id <= currentMonth.days)}
                            onChange={(e) => {
                                let totalDays = getDaysFromMonth(getMonthAndDay(this.props.billingCycleStartDay).month);
                                totalDays += Number(e.target.value - 1);
                                this.props.onBillingCycleStartDayChange(totalDays);
                            }}/>
                    </div>
                    </div>
                </div>
                }
            </div>)
    }
}

export default BillingCyclePicker;
