import React from 'react';
import './LeftNav.scss';
import {Icon, Tooltip, Loader, Input, Button} from 'gd-react'
import logo from '../images/gridduck-brand.png';
import biyLogo from '../images/biy-energy-logo-left-nav.png';
import abbLogo from '../images/abb-logo.png';
import smsLogo from '../images/sms-logo.png';
import centricaLogo from '../images/centrica-logo.png';
import mGroup from '../images/mgroup-logo.png';
import HagerLogo from '../images/hager-logo.png';
import BoschLogo from '../images/bosch-logo.png';
import SSELogo from '../images/sse-logo.png';
import AvailsLogoOnWhite from '../images/avails-on-white.png';
import AvailsLogoOnBlack from '../images/avails-on-dark.png';
import ConnectusLogoOnWhite from '../images/connectus-on-white.png';
import ConnectusLogoOnBlack from '../images/connectus-on-black.png';
import CallistoLogoOnWhite from '../images/callisto-logo.png';
import CallistoLogoOnBlack from '../images/callisto-logo-on-black.png';
import TAGLogo from '../images/TAG-logo.png';
import InspiredLogo from '../images/inspired-logo.png';
import cookie from 'react-cookies'
import history from "../meta/history"
import GridDuck from "gridduck";
import LeftNavMiniListItem from "./LeftNavMiniListItem";
import PortfolioList from "./PortfolioList/PortfolioList";
import GenericLoader from "../components/GenericLoader";
import {addCookie, getCookie} from "../services/cookieManager";
import LeftNavDropdown from "../components/LeftNavDropDown";

let svgLogo = <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                   viewBox="0 0 585 153">
    <g>
        <path className="st1" d="M234.91,74.44h29.07v2.32c0,5.27-0.62,9.95-1.86,14.03c-1.22,3.77-3.27,7.28-6.16,10.57
		c-6.54,7.35-14.85,11.03-24.92,11.03c-9.83,0-18.26-3.56-25.27-10.67c-7.02-7.14-10.53-15.7-10.53-25.7
		c0-10.21,3.58-18.85,10.72-25.97c7.14-7.14,15.82-10.72,26.06-10.72c5.49,0,10.62,1.12,15.39,3.37c4.56,2.24,9.05,5.9,13.44,10.93
		l-7.57,7.26c-5.78-7.69-12.79-11.53-21.1-11.53c-7.45,0-13.68,2.58-18.74,7.71c-5.04,5.04-7.57,11.36-7.57,18.95
		c0,7.83,2.82,14.3,8.42,19.36c5.25,4.7,10.95,7.06,17.09,7.06c5.23,0,9.9-1.77,14.08-5.27c4.15-3.56,6.49-7.8,6.97-12.74h-17.54
		C234.91,84.42,234.91,74.44,234.91,74.44z"/>
        <path className="st1" d="M275.36,66.71h10.26v3.96c1.89-1.98,3.56-3.32,5.01-4.06c1.48-0.76,3.25-1.15,5.27-1.15
		c2.7,0,5.54,0.88,8.47,2.65l-4.7,9.38c-1.93-1.41-3.84-2.1-5.7-2.1c-5.58,0-8.38,4.22-8.38,12.67v23.01h-10.26V66.71H275.36z"/>
        <path className="st1" d="M310.14,48.26c0-1.79,0.64-3.34,1.96-4.65c1.31-1.31,2.86-1.96,4.7-1.96c1.86,0,3.44,0.64,4.75,1.96
		c1.31,1.26,1.96,2.84,1.96,4.7s-0.64,3.44-1.96,4.75c-1.26,1.31-2.84,1.96-4.7,1.96c-1.86,0-3.44-0.64-4.75-1.96
		C310.78,51.74,310.14,50.14,310.14,48.26z M321.93,66.71v44.37h-10.26V66.71H321.93z"/>
        <path className="st1" d="M364.63,40.62h10.29v70.45h-10.29v-4.65c-4.03,3.94-8.62,5.92-13.72,5.92c-6.09,0-11.12-2.22-15.13-6.66
		c-3.99-4.53-5.97-10.17-5.97-16.95c0-6.61,1.98-12.15,5.97-16.59c3.94-4.46,8.9-6.71,14.89-6.71c5.2,0,9.83,2.12,13.94,6.37V40.62
		H364.63z M340.29,88.76c0,4.25,1.15,7.71,3.41,10.38c2.34,2.7,5.27,4.06,8.83,4.06c3.79,0,6.87-1.31,9.21-3.91
		c2.34-2.7,3.51-6.13,3.51-10.29c0-4.15-1.17-7.59-3.51-10.29c-2.34-2.65-5.37-3.96-9.12-3.96c-3.53,0-6.47,1.34-8.83,4.01
		C341.46,81.43,340.29,84.77,340.29,88.76z"/>
        <path className="st1" d="M388.81,111.07V40.74h14.75c7.09,0,12.67,0.69,16.75,2.1c4.42,1.36,8.4,3.7,11.98,6.97
		c7.26,6.61,10.88,15.32,10.88,26.11c0,10.81-3.77,19.57-11.34,26.25c-3.79,3.34-7.78,5.66-11.93,6.97
		c-3.89,1.31-9.4,1.96-16.54,1.96h-14.56V111.07z M399.43,101.1h4.77c4.77,0,8.74-0.5,11.89-1.5c3.15-1.07,6.01-2.74,8.57-5.06
		c5.23-4.77,7.83-10.98,7.83-18.64c0-7.71-2.58-13.96-7.76-18.76c-4.65-4.27-11.5-6.42-20.55-6.42h-4.77v50.38H399.43z"/>
        <path className="st1" d="M463.85,66.71v25.47c0,7.35,2.91,11.03,8.71,11.03s8.71-3.68,8.71-11.03V66.71h10.26v25.7
		c0,3.56-0.43,6.61-1.31,9.21c-0.86,2.32-2.32,4.39-4.42,6.25c-3.46,3.01-7.88,4.51-13.22,4.51c-5.32,0-9.71-1.5-13.17-4.51
		c-2.12-1.86-3.63-3.94-4.51-6.25c-0.86-2.08-1.26-5.13-1.26-9.21v-25.7H463.85z"/>
        <path className="st1" d="M535.98,68.62v13.63c-2.34-2.86-4.44-4.82-6.28-5.87c-1.81-1.1-3.96-1.65-6.42-1.65
		c-3.87,0-7.06,1.36-9.62,4.06c-2.55,2.7-3.82,6.09-3.82,10.17c0,4.15,1.24,7.59,3.7,10.26c2.48,2.67,5.66,4.01,9.52,4.01
		c2.46,0,4.63-0.53,6.52-1.6c1.81-1.03,3.96-3.03,6.42-6.01v13.53c-4.15,2.15-8.33,3.25-12.48,3.25c-6.87,0-12.6-2.22-17.23-6.66
		c-4.61-4.46-6.92-10-6.92-16.64c0-6.61,2.34-12.22,7.02-16.75c4.68-4.56,10.41-6.83,17.23-6.83
		C527.96,65.47,532.09,66.52,535.98,68.62z"/>
        <path className="st1" d="M557.58,40.83v40.22l14.44-14.34h13.75l-19.26,18.64l20.69,25.75h-13.29l-14.68-18.74l-1.65,1.65v17.09h-10.26
		V40.83H557.58z"/>
    </g>
    <g>
        <circle className="st1" cx="78.43" cy="58.75" r="4.84"/>
        <path className="st1" d="M76.49,0L76.49,0C34.24,0,0,34.27,0,76.51S34.24,153,76.49,153l0,0c42.24,0,76.49-34.24,76.49-76.49
		S118.73,0,76.49,0z M42.84,117.35l1.49-41.23V61.38h0.09c1.1-14.39,13.14-25.7,27.79-25.7c15.4,0,27.88,12.48,27.88,27.88
		c0,0.18,0,0.36,0,0.54l0,0l-4.57,9.02L73.2,86.84l0.09,0.15l-0.33-0.03l2.99,30.39H42.84z M125.06,90.84l-49.53-3.7l21.11-12.99
		l4.57-9.05l2.03,1.82c5.1,4.63,10.96,8.12,17.23,10.24l4.6,1.55V90.84z"/>
    </g>
</svg>

class LeftNav extends React.Component {
    constructor(props) {
        super(props);
        this.goToPage = this.goToPage.bind(this);
        //Use cookies to save the state of this
        console.log(cookie.load('menuState'), ' : cookie.load(\'menuState\')')
        this.state = {
            open: cookie.load('menuState') ? cookie.load('menuState') === 'true' : true,
            updateRef: 0,
            upperItemsClosed: cookie.load('upperMenuState') ? cookie.load('upperMenuState') === 'true' : false,
            searchText: ''
        };
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleUpperMenu = this.toggleUpperMenu.bind(this);
        this.resizeLeftNav = this.resizeLeftNav.bind(this);
        this.loadUser = this.loadUser.bind(this);
        // this.incidentsChanged = this.incidentsChanged.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.mouseUpEvent = this.mouseUpEvent.bind(this);

        //Top Menu Items
        this.topMenuItems = [
            // {
            //     name: 'home',
            //     icon: 'FaHome'
            // },
            {
                name: 'AI Alerts (beta)',
                url: 'anomaly-detection',
                icon: 'FaRobot',
                demo: true,
                demoStage: 'alpha',
                godOnly: true
            },
            {
                name: 'Reports',
                url: 'reports',
                icon: 'FaChartArea'
            },
            {
                name: 'Alerts',
                url: 'alerts',
                icon: 'FaBell'
            },
            {
                name: 'Automations',
                url: 'automations',
                icon: 'FaRobot'
            },
            // {
            //     name: 'Device Groups',
            //     icon: 'FaCubes',
            //     url: 'device-groups'
            // },
            {
                name: 'Invoices',
                url: 'invoices',
                icon: 'FaFileInvoice'
            },
            {
                name: 'Data Downloads',
                url: 'data-download',
                icon: 'FaFileDownload'
            },
            // {
            //     name: 'Tariffs',
            //     url: 'tariffs',
            //     icon: 'FaMoneyBillWave'
            // },
            // {
            //     name: 'Custom Fields',
            //     url: 'custom-fields',
            //     icon: 'MdPlaylistAdd'
            // },

        ]

        let onDeviceGroup = ~window.location.pathname.indexOf('deviceGroup');
        console.log(onDeviceGroup, ' : onDeviceGroup');

        this.tabs = [[
            {
                id: 1,
                title: 'Site Groups',
                icon: 'FaGlobeEurope',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 1 || !this.props.openOnTab) && !onDeviceGroup
            },
            {
                id: 2,
                title: 'Device Groups',
                icon: 'FaCubes',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 2) || onDeviceGroup
            },
            // {
            //     id: 3,
            //     title: 'Clients',
            //     icon: 'FaBriefcase',
            //     onTabClick: this.onTabClick,
            //     selected: (this.props.openOnTab === 3) || onDeviceGroup
            // }
        ]];

        history.listen((location, action) => {
            this.setState({updateRef: this.state.updateRef++});
        });
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (account) {
                self.account = account;
                self.account.on('updated', self.loadUser);
                console.log(account, ' : account');
                self.setState({
                    accountLoaded: true,
                    whiteLabel: account.whiteLabel,
                    logoImage: account.logoImageThumbnail,
                    darkLight: account.darkLight,
                    inheritWhiteLabelFrom: account.inheritWhiteLabelFrom
                });
                // self.incidentsChanged();
            })

        // GridDuck.getIssues({
        //     getAll: true,
        //     items: 200
        // })
        //     .then(function (incidents) {
        //         self.incidents = incidents;
        //         self.incidents.on('updated', self.incidentsChanged);
        //         self.incidentsChanged();
        //     })
    }

    async componentDidMount() {
        let lnw = getCookie('leftNavWidth');
        if (lnw) {
            lnw = parseInt(lnw);
            this.leftNavDom = this.leftNavDom || document.getElementById('left_nav');
            if (this.leftNavDom) {
                this.leftNavWidth = lnw;
                window.requestAnimationFrame(() => this.leftNavDom.style.width = lnw + "px")
            }
        }
        let clientOption = {
            label: 'Clients',
            value: 'client',
            childrenLabel: 'site',
            labelField: 'orgToName',
            childrenFilters: [
                {field: 'orgPartnerId', valueField: 'id'}
            ],
            urlLabel: 'client',
            icon: {
                color: 'gd-grey',
                name: 'FaBriefcase',
                size: '12'
            }
        };
        let groupByOptions = [
            // {
            //     type: 'divider',
            //     title: 'Group By'
            // },
            {
                label: 'Site Groups',
                value: 'site_group',
                urlLabel: 'siteGroup',
                labelField: 'name',
                childrenLabel: 'site',
                addNew: () => this.setState({openCreateSiteGroupModal: true}),
                childrenFilters: [
                    {field: 'siteGroupId', valueField: 'id'}
                ],
                icon: {
                    color: 'gd-grey',
                    name: 'FaGlobeEurope',
                    size: '12'
                }
            },
            {
                label: 'Device Groups',
                value: 'device_group',
                // noItems: {
                //     label: 'You have no device groups', button: <Button label={'Create one'} onClick={() => {
                //         this.setState({openCreateDeviceGroupModal: true})
                //     }}/>
                // },
                childrenLabel: 'device',
                urlLabel: 'deviceGroup',
                loadChildrenFilterField: 'id',
                loadChildrenValueField: 'assetIds',
                childrenFilters: [
                    {field: 'id', valueField: 'assetIds'}
                ],
                labelField: 'name',
                icon: {
                    color: 'gd-grey',
                    name: 'FaCubes',
                    size: '12'
                }
            },
            {
                label: 'Device Categories',
                value: 'device_category',
                disabled: {
                    message: 'Coming Soon',
                    position: 'right'
                },
                labelField: 'name',
                childrenLabel: 'device',
                childrenFilters: [
                    {field: 'deviceCategoryId', valueField: 'id'}
                ],
                urlLabel: 'deviceGroup',
                icon: {
                    color: 'gd-grey',
                    name: 'AiOutlineAppstoreAdd',
                    size: '12'
                }
            },
            {
                label: 'Custom Fields',
                disabled: {
                    message: 'Coming Soon',
                    position: 'right'
                },
                value: 'custom_field',
                icon: {
                    color: 'gd-grey',
                    name: 'FaEdit',
                    size: '12'
                }
            }
        ]
        let groupBy = groupByOptions[0];

        let partners = await GridDuck.getOrgPartners({
            items: 1,
            filters: [
                {field: 'type', value: 'customer'},
                {field: 'owner', value: true}
            ]
        });
        console.log(partners, ' : partners');
        let has_partners = partners.total;

        if (has_partners) {
            groupByOptions.splice(2, 0, clientOption);
        }

        if (window.location.pathname.indexOf('client') !== -1) {
            groupBy = groupByOptions.find(gbo => gbo.value === 'client');
        }
        if (window.location.pathname.indexOf('deviceGroup') !== -1) {
            groupBy = groupByOptions.find(gbo => gbo.value === 'device_group');
        }
        if (window.location.pathname.indexOf('siteGroup') !== -1) {
            groupBy = groupByOptions.find(gbo => gbo.value === 'site_group');
        }

        this.setState({groupBy: groupBy, groupByOptions: groupByOptions});
    }

    mouseUpEvent() {
        document.getElementsByTagName("body")[0].style.userSelect = 'auto';
        window.removeEventListener("mousemove", this.resizeLeftNav);
        window.removeEventListener("mouseup", this.mouseUpEvent);
        addCookie('leftNavWidth', this.leftNavWidth);
        this.mouseDownOnDragHandle = false;
    }

    loadUser() {
        let self = this;
        console.log('Load user: ', this.account);
        if (this.account) {
            self.setState({
                whiteLabel: this.account.whiteLabel,
                logoImage: this.account.logoImageThumbnail,
                darkLight: this.account.darkLight,
                inheritWhiteLabelFrom: this.account.inheritWhiteLabelFrom
            });
        }
    }

    resizeLeftNav(e, self) {
        this.leftNavDom = this.leftNavDom || document.getElementById('left_nav');
        if (this.leftNavDom && this.mouseDownOnDragHandle) {
            this.leftNavWidth = e.clientX;
            window.requestAnimationFrame(() => this.leftNavDom.style.width = e.clientX + "px")
        }
    }

    componentWillUnmount() {
        if (this.account) {
            this.account.off('updated', this.loadUser);
        }
    }

    goToPage(page) {
        history.push("/" + page);
    }

    toggleMenu() {
        console.log(this.state.open, ' : open');
        let newState = this.state.open ? 'false' : 'true';
        this.setState({open: !this.state.open}, () => {
            console.log(newState, ' : new state');
            cookie.save('menuState', newState, {path: '/'});
        });
    }

    toggleUpperMenu() {
        cookie.save('upperMenuState', !this.state.upperItemsClosed, {path: '/'});
        this.setState({upperItemsClosed: !this.state.upperItemsClosed})
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.state.searchText = '';
        this.setState(this.state);
    }

    render() {
        let self = this;
        const menuOpen = this.state.open;
        console.log(this.state, ' : this.state');
        let _logo;

        if (window.location.hostname.indexOf('hub.biyenergy.com') !== -1) {
            _logo = biyLogo
        }
        if (window.location.hostname.indexOf('abb.gridduck') !== -1) {
            _logo = abbLogo
        }
        if (window.location.hostname.indexOf('sms.gridduck') !== -1) {
            _logo = smsLogo
        }
        if (window.location.hostname.indexOf('centrica.gridduck') !== -1) {
            _logo = centricaLogo
        }
        if (window.location.hostname.indexOf('mgroup.gridduck') !== -1) {
            _logo = mGroup
        }
        if (window.location.hostname.indexOf('hager.gridduck') !== -1) {
            _logo = HagerLogo
        }
        if (window.location.hostname.indexOf('bosch.gridduck') !== -1) {
            _logo = BoschLogo
        }
        if (window.location.hostname.indexOf('sse.gridduck') !== -1) {
            _logo = SSELogo
        }
        if (window.location.hostname.indexOf('tag.gridduck') !== -1) {
            _logo = TAGLogo
        }
        if (window.location.hostname.indexOf('inspired.gridduck') !== -1) {
            _logo = InspiredLogo
        }
        if ((this.state.whiteLabel || this.state.inheritWhiteLabelFrom) && this.state.logoImage) {
            console.log('Load in logo: ', this.state.logoImage);
            _logo = this.state.logoImage;
        }
        if (window.location.hostname.indexOf('hub.avails.co.uk') !== -1) {
            if (this.state.darkLight === 'light') {
                _logo = AvailsLogoOnWhite;
            } else {
                _logo = AvailsLogoOnBlack;
            }
        }
        if (window.location.hostname.indexOf('callisto.gridduck') !== -1) {
            if (this.state.darkLight === 'light') {
                _logo = CallistoLogoOnWhite;
            } else {
                _logo = CallistoLogoOnBlack;
            }
        }
        if (window.location.hostname.indexOf('connectus.gridduck') !== -1) {
            if (this.state.darkLight === 'light') {
                _logo = ConnectusLogoOnWhite;
            } else {
                _logo = ConnectusLogoOnBlack;
            }
        }

        let siteGroup;
        if (this.state.accountLoaded) {
            siteGroup = `siteGroup/all_` + self.account.id + '_' + self.account.organisationId;
        }

        console.log(this.state, '  state')

        return [(
            <div id="left_nav"
                 key={"left-nav"}
                 className={"" + (!this.state.open ? 'closed' : '') + ((window.location.hostname.indexOf('dashboard.gridduck.com') === -1 && window.location.hostname !== 'localhost') || ((this.state.whiteLabel || this.state.inheritWhiteLabelFrom) && this.state.logoImage) ? ' powered-by' : '')}>

                <div className={'menu-inner-content'}>
                    <div className={'upper-menu-items ' + (this.state.upperItemsClosed ? 'closed' : '')}>
                        <div className={'menu-header'}>
                            {this.account ? _logo ?
                                    <img style={{cursor: 'pointer'}} onClick={() => history.push('/siteGroup/all')}
                                         alt={'GridDuck'} className={'logo'}
                                         src={_logo}/> : <div style={{cursor: 'pointer'}}
                                                              onClick={() => history.push('/siteGroup/all')}>{svgLogo}</div> :
                                <div style={{padding: '10px 20px'}}><Loader/></div>}
                        </div>

                        <div className={'inner-items'}>

                            {this.account && this.topMenuItems.map((tmi, i) => {
                                return <LeftNavMiniListItem key={i + 'lnmi'}
                                                            icon={tmi.icon}
                                                            name={tmi.name}
                                                            demo={tmi.demo}
                                                            godOnly={tmi.godOnly}
                                                            url={tmi.url}
                                                            loaded={this.state.accountLoaded}
                                                            account={this.account}
                                                            open={this.state.open}/>
                            })}

                        </div>
                        {this.state.accountLoaded ? <div className={'see-more-less'}
                                                         onClick={this.toggleUpperMenu}>
                            {this.state.open ? <p>Show {this.state.upperItemsClosed ? 'More' : 'Less'}</p> : null}
                            <Icon size={'8'} icon={this.state.upperItemsClosed ? 'FaChevronDown' : 'FaChevronUp'}/>
                        </div> : ''}
                    </div>

                    {/*{this.state.sitesLoaded && !this.state.noSites && this.state.open ?*/}
                    {/*    <Tabs tabData={this.tabs}/> : null}*/}
                    {this.state.groupByOptions && this.state.groupBy ?
                        <LeftNavDropdown open={this.state.open} value={this.state.groupBy?.value}
                                         options={this.state.groupByOptions.map(gbo => {
                                             gbo.onMenuItemClick = () => {
                                                 console.log('Set groupBy to: ', gbo);
                                                 self.setState({groupBy: gbo});
                                             }
                                             return gbo;
                                         })}/> : null}
                    {/* TODO Add Tabs for Site Groups / Device Groups*/}
                    {this.state.sitesLoaded && !this.state.noSites && this.state.open ?
                        <div className={'search-input-wrapper'}><Input openOnFocus={false} InputProps={{
                            startAdornment: (
                                <div className={'input-icon'}>
                                    <Icon color={'grey'} size={'10'} icon={'FaSearch'}/>
                                </div>
                            ),
                            endAdornment: this.state.searchText.length ? (
                                <div onClick={() => this.setState({searchText: ''})} className={'input-icon end'}>
                                    <Icon color={'gd-red'} size={'10'} icon={'FaTimes'}/>
                                </div>) : ''
                        }} value={this.state.searchText} placeholder={'Search'}
                                                                       onChange={(e) => this.setState({searchText: e.target.value})}/>
                        </div> : null}

                    {this.state.accountLoaded && this.state.groupBy ?
                        <div className={'menu-items'}>
                            <div className={'menu-items-scroller'}>
                                <PortfolioList
                                    updateDisconnectedDrawer={this.props.updateFunctions.updateDisconnectedDrawer}
                                    onLoaded={(noSites) => {
                                        console.log("Loaded");
                                        this.setState({sitesLoaded: true, noSites: noSites})
                                    }} groupType={this.state.groupBy} searchText={this.state.searchText}
                                    open={this.state.open}/>
                            </div>

                        </div> : ''}
                    {/*{this.state.accountLoaded && this.tabs[0][1] && this.tabs[0][1].selected ?*/}
                    {/*    <div className={'menu-items'}>*/}
                    {/*        <div className={'menu-items-scroller'}>*/}
                    {/*            <PortfolioList onLoaded={(noDevices) => {*/}
                    {/*                console.log("Loaded");*/}
                    {/*                this.setState({sitesLoaded: true, noDevices: noDevices})*/}
                    {/*            }} type={'device'} groupType={'deviceGroup'} searchText={this.state.searchText}*/}
                    {/*                           open={this.state.open}/>*/}
                    {/*        </div>*/}

                    {/*    </div> : ''}*/}
                </div>
                <div className={'lock-icon'} onClick={this.toggleMenu}>
                    <Tooltip label={menuOpen ? 'Minimise' : 'Expand'}
                             position={'right'}>
                        <Icon size={'17'} color={'gd-grey'} icon={menuOpen ? 'IoIosArrowBack' : 'IoIosArrowForward'}/>
                    </Tooltip>
                </div>
                {(window.location.hostname.indexOf('dashboard.gridduck.com') === -1 && window.location.hostname !== 'localhost') || ((this.state.whiteLabel || this.state.inheritWhiteLabelFrom) && this.state.logoImage) ?
                    <div className={'powered-by ' + (!this.state.open ? 'closed' : '')}>
                        {this.state.open ? <p>Powered by</p> : ''}
                        {this.state.open ? <a href={'https://gridduck.com'} target={'_blank'}>
                            <div className={'pb-logo'}><img alt={'GridDuck'} className={'pb-logo'} src={logo}/></div>
                        </a> : <Tooltip position={'top'} label={'Powered by GridDuck'}><a href={'https://gridduck.com'}
                                                                                          target={'_blank'}>
                            <div className={'pb-logo'}><img alt={'GridDuck'} className={'pb-logo'} src={logo}/></div>
                        </a></Tooltip>}
                    </div> : null}
            </div>
        ), (this.state.open ? <div key="onMouseDown" onMouseDown={() => {
            console.log('Mouse down on handle');
            document.getElementsByTagName("body")[0].style.userSelect = 'none';
            this.mouseDownOnDragHandle = true;
            window.addEventListener("mousemove", this.resizeLeftNav);
            window.addEventListener("mouseup", this.mouseUpEvent);
        }} className={'drag-handle'}/> : null)];
    }
}

export default LeftNav;
