import React from "react";
import "./DataPage.scss";
import {Toast, Icon, Dropdown, GdCheckbox, Button} from "gd-react";
import Legend from "../../components/Legend";
import KPI from "../../components/KPI";
import GenericLoader from "../../components/GenericLoader";
import DataPage from "./DataPage";
import GraphTypePicker from "../../components/GraphTypePicker";
import GridDuck from 'gridduck';
import * as _ from 'underscore';
import LegendRange from "../../components/LegendRange";
import EditLocationModal from "../LocationModal";
import OccupancyScheduleSetterMenu from "../../components/OccupancyScheduleSetterMenu";

const detachedLimit = 4;

class DesktopDataPage extends DataPage {

    render() {
        let self = this;
        let loader;
        let legend;
        let timezone = this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London';
        let isAll = this.props.item.id.indexOf('all') !== -1;

        let _legendRange = <LegendRange
            summaryMode
            detachedLimit={detachedLimit}
            withGranularity
            onIsComparingChange={() => {
            }}
            onSelectionTypeChange={() => {
            }}
            // disabled={!this.state.ready}
            onHover={this.onHighlight}
            overlays={this.state.overlays}
            setOverlays={this.setOverlays}
            onHide={this.onHide}
            resetZoom={this.resetZoom}
            zoomData={this.state.zoomData}
            defaultSelected={this.state.defaultSelected}
            category={self.props.tab.tab.dataType.category}
            graph={this.state.graph}
            dRString={this.dRString}
            cRString={this.cRString}
            granularity={this.granularity}
            graphType={this.state.type}
            start={this.start}
            timezone={timezone}
            time={this.state.time}
            end={this.end}
            set={this.set}
            isAll={isAll}
            combined={this.state.combined}
            compareStart={this.compareTo ? this.compareTo.start : null}
            compareEnd={this.compareTo ? this.compareTo.end : null}
            displayName={this.props.item?.name}
            item={this.props.item}
            site={this.props.site}
            disabled={!this.state.ready}
            highlighted={this.state.highlighted}
            items={this.state.visibleItems}
            data={this.state.data}
            locked={this.state.data ? this.state.data.locked : false}
        />

        legend = (<Legend
            parentRef={this.parentRef}
            showDetached={this.state.showDetached}
            tooltipRef={this.tooltipRef}
            mx={this.state.m_x}
            my={this.state.m_y}
            detachedLimit={detachedLimit}
            displayName={this.props.item?.name}
            item={this.props.item}
            site={this.props.site}
            disabled={!this.state.ready}
            onHover={this.onHighlight}
            overlays={this.state.overlays}
            setOverlays={this.setOverlays}
            onHide={this.onHide}
            highlighted={this.state.highlighted}
            resetZoom={this.resetZoom}
            zoomData={this.state.zoomData}
            items={this.state.visibleItems}
            data={this.state.data}
            defaultSelected={this.state.defaultSelected}
            locked={this.state.data ? this.state.data.locked : false}
            category={self.props.tab.tab.dataType.category}
            graph={this.state.graph}
            dRString={this.dRString}
            cRString={this.cRString}
            granularity={this.granularity}
            graphType={this.state.type}
            start={this.start}
            timezone={timezone}
            time={this.state.time}
            end={this.end}
            set={this.set}
            isAll={isAll}
            combined={this.state.combined}
            compareStart={this.compareTo ? this.compareTo.start : null}
            compareEnd={this.compareTo ? this.compareTo.end : null}
        />);
        let graphClass = '';
        if (!this.state.ready) {
            loader = (
                <GenericLoader id={'graph-loader'} text={'Fetching Data...'} textLineTwo={'This may take a while'}/>);
            graphClass += 'loading';
        }
        if (this.state.zooming) {
            loader = (<GenericLoader id={'graph-loader'} text={'Applying Changes...'}
                                     textLineTwo={'Should only take a moment...'}/>);
            graphClass += 'loading';
        }
        let noData;
        if (this.state.noData) {
            noData = (<div className={"no-data"}>
                <Icon icon={'MdInsertChart'} size={'40'}/>
                <p className={'no-data-title'}>No data</p>
                <p>There are no records in this time range.</p>
            </div>)
        }
        if (this.state.error) {
            noData = (<div className={"no-data"}>
                <Icon icon={'MdInsertChart'} size={'40'}/>
                <p className={'no-data-title'}>Something went wrong</p>
                <p>There was an issue whilst retrieving your data.</p>
            </div>)
        }
        let granularityOptions = ['today_so_far', 'yesterday', 'last_7_days', 'last_30_days', 'this_month', 'last_month', 'this_year', 'last_year', 'custom'];
        if (this.props.tab.tab.dataType.type === 'CONSUMPTION') {
            granularityOptions = [
                '15_mins', 'last_hour', 'last_24_hours', 'today_so_far', 'yesterday', 'last_7_days', 'last_30_days', 'this_month', 'last_month', 'this_year', 'last_year', 'custom'
            ];
        }
        let kpiHeight;
        kpiHeight = this.graphKPIRef?.current?.clientHeight || 0
        kpiHeight += 'px'
        let dataTabs = this.props.tabs.find(t => t.title === 'Data');
        let picker = [(<GraphTypePicker onChange={this.graphTypeChange}
                                        type={this.state.type}
                                        dataType={this.props.tab.tab.dataType}
                                        key={'gtp'}
                                        dRString={this.dRString}
                                        combined={this.state.combined}
                                        graph={this.state.graph}
                                        isAll={isAll}
                                        time={this.state.time}
                                        filterType={this.props.filterType}
                                        filterId={this.props.filterId}
                                        average={this.state.average}
                                        dataTabs={dataTabs.tabs}
                                        category={this.state.category}/>)]


        let _dataCategories = Object.entries(_.groupBy(dataTabs.tabs, tab => tab.dataType.category)).map(([key, value]) => {
            return {
                id: key,
                icon: value[0].icon,
                color: value[0].color,
                title: key,
                value: key.toLowerCase()
            }
        });
        let _dataTypes = dataTabs.tabs.map((dt, i) => {
            return {
                id: dt.title,
                title: dt.title,
                value: dt.dataType.type.toLowerCase(),
                category: dt.dataType.category,
                dataType: dt.dataType
            }
        });
        let filteredTypes;
        if (_dataTypes) {
            filteredTypes = _dataTypes.filter(dt => {
                let sameCat = (dt.category === this.props.tab.tab.dataType.category);
                let notOnlyValueIsCat = (dt.id !== this.props.tab.tab.dataType.category);
                let sibling = dataTabs.tabs.find(_dtab => _dtab.dataType.type === dt.dataType.dataTypeSibling);
                let noSiblingOrAggregateWithSibling = (!dt.dataType.dataTypeSibling || (sibling && sibling.dataType.dataType === 'instantaneous'));
                return sameCat && notOnlyValueIsCat && noSiblingOrAggregateWithSibling;
            })
        }

        let tabDt = this.props.tab.tab.dataType;
        let selectedDt = this.props.tab.tab.dataType.type.toLowerCase();
        if (tabDt.dataType === 'instantaneous' && tabDt.dataTypeSibling) {
            selectedDt = tabDt.dataTypeSibling.toLowerCase();
        }

        let _site = this.props.site || this.props.item;

        return (
            <div className={"data-graphs"}>
                <Toast onClose={() => this.setState({showNoTempData: null})}
                       message={<div>
                           <p style={{color: 'orange'}}>No MET temperature data available.</p>
                           <p style={{color: 'orange'}}>This could be for a few reasons:</p>
                           <ul>
                               <li><p style={{margin: 0}}>Not enough time has passed since creating the site</p></li>
                               <li><p style={{margin: 0}}>There is no weather station within range of your address</p>
                               </li>
                               <li><p style={{margin: 0}}>You haven't yet added a postcode to your address</p>
                               </li>
                           </ul>
                           <div className={'row reverse'} style={{marginBottom: '5px', marginTop: '15px'}}>
                               <Button additionalclasses={'sm'} label={'Manage address'} onClick={async () => {
                                   let siteId = this.props.item.objectTypeName === 'site' ? this.props.item.id : this.props.item.siteId;
                                   let site = await GridDuck.getSite({id: siteId});
                                   if (site.locationId) {
                                       let location = await GridDuck.getLocations({
                                           filters: [{
                                               field: 'id',
                                               value: site.locationId
                                           }]
                                       })
                                       self.setState({showEditReportModal: location.list[0]});
                                       self.setState({showNoTempData: null})
                                   }
                               }}/>
                           </div>
                       </div>}
                       open={!!(this.state.showNoTempData)}
                       severity="warning"
                       anchorOrigin={{
                           vertical: 'center',
                           horizontal: 'end',
                       }}/>
                {this.state.showEditReportModal ? <EditLocationModal onClose={() => {
                    this.setState({showEditReportModal: null});
                }} open={!!this.state.showEditReportModal} item={this.state.showEditReportModal}/> : null}
                <div className={'graph-kpi-legend-wrapper'}>

                    <div ref={this.graphKPIRef} className={'graph-kpi-wrapper'}>
                        <div className={'TabRow with-dd'}>
                            {/*{Object.keys(_.groupBy(this.props.availableDataTypes, 'category')).map(category => (*/}
                            {/*    <p>{category}</p>))}*/}
                            {/*<div className={'minimal-dropdown ultra-minimal swiper-no-swiping'}>*/}

                            <div className={'no-border-dropdown'}>
                                {dataTabs && dataTabs.tabs.length ?
                                    <Dropdown value={this.props.tab.tab.dataType.category.toLowerCase()}
                                              borderless
                                              label={''} nolabel={'true'}
                                              fixeditems={_dataCategories}
                                              onChange={(val) => {
                                                  self.props.changeTab(['data', _dataTypes.find(dt => dt.category.toLowerCase() === val.target.value).value])()
                                              }}/> : null}
                                {filteredTypes && filteredTypes.length ?
                                    <Dropdown value={selectedDt}
                                              borderless
                                              label={''} nolabel={'true'}
                                              fixeditems={filteredTypes}
                                              onChange={(val) => {
                                                  self.props.changeTab(['data', val.target.value])()
                                              }}/> : null}
                            </div>
                            {/*</div>*/}
                            {/*{this.props.availableDataTypes[0].label}*/}
                            {_legendRange}
                        </div>
                        <div className={'TabRow with-dd'}>
                            <div>
                                {picker}
                            </div>
                            {this.props.tab.tab.dataType.dataType === 'instantaneous' && this.props.item.objectTypeName === 'site' ?
                                <div>

                                    <Dropdown nolabel='true'
                                              key={'ag-dd'}
                                              faked
                                              count={this.state.overlays ? this.state.overlays.length : 0}
                                              id={'overlays'}
                                              borderless
                                              icon={'FaLayerGroup'}
                                              placeholder={'Overlays'}
                                              onChange={(e) => {
                                              }}
                                              overrideItems
                                              fixeditems={[
                                                  <div className={'menu-divider'} onClick={(e) => {
                                                      if (e) e.stopPropagation();
                                                  }}>
                                                      Overlays
                                                  </div>,
                                                  <div className={'fake-menu-body'} onClick={(e) => {
                                                      if (e) e.stopPropagation();
                                                  }}>
                                                      <div className={'menu-checkbox'}>
                                                          <GdCheckbox
                                                              checked={this.state.overlays.indexOf('TEMPERATURE') !== -1}
                                                              name={'overlayTemperature'}
                                                              onChange={(e) => {
                                                                  if (this.state.overlays.indexOf('TEMPERATURE') !== -1) {
                                                                      this.state.overlays.splice(this.state.overlays.indexOf('TEMPERATURE'), 1);
                                                                  } else {
                                                                      this.state.overlays.push('TEMPERATURE');
                                                                  }
                                                                  this.setOverlays(this.state.overlays)
                                                              }}
                                                              label={'External Temperature'}/>
                                                          <div className={'row'}>
                                                              {(_site.occupancyScheduleId || _site.defaultOccupancyScheduleId) ?
                                                                  [(<GdCheckbox
                                                                      checked={this.state.overlays.indexOf('OCCUPANCY') !== -1}
                                                                      name={'overlayOccupancy'}
                                                                      onChange={function (e) {
                                                                          if (e) e.stopPropagation();
                                                                          if (self.state.overlays.indexOf('OCCUPANCY') !== -1) {
                                                                              self.state.overlays.splice(self.state.overlays.indexOf('OCCUPANCY'), 1);
                                                                          } else {
                                                                              self.state.overlays.push('OCCUPANCY');
                                                                          }
                                                                          self.setOverlays(self.state.overlays)
                                                                      }}
                                                                      label={'Occupancy Hours'}/>), (
                                                                      <div>
                                                                          <OccupancyScheduleSetterMenu site={_site}
                                                                                                       key={'occupancy-schedule-menu'}
                                                                                                       onComplete={(res) => {
                                                                                                           if (self.state.overlays.indexOf('OCCUPANCY') === -1) {
                                                                                                               self.state.overlays.push('OCCUPANCY');
                                                                                                           }
                                                                                                           self.setOverlays(self.state.overlays);
                                                                                                       }}>
                                                                              <div><Icon color={'grey'} size={'9px'}
                                                                                         onIconClick={(e) => {
                                                                                         }}
                                                                                         icon={'FaCog'}/></div>
                                                                          </OccupancyScheduleSetterMenu></div>)] :
                                                                  <OccupancyScheduleSetterMenu
                                                                      ref={this.customKpiMenuRef}
                                                                      site={_site}
                                                                      key={'occupancy-schedule-menu-2'}
                                                                      onComplete={(res) => {
                                                                          if (self.state.overlays.indexOf('OCCUPANCY') === -1) {
                                                                              self.state.overlays.push('OCCUPANCY');
                                                                          }
                                                                          self.setOverlays(self.state.overlays);
                                                                      }}>
                                                                      <GdCheckbox
                                                                          checked={this.state.overlays.indexOf('OCCUPANCY') !== -1}
                                                                          name={'overlayOccupancy'}
                                                                          label={'Occupancy Hours'}/>
                                                                  </OccupancyScheduleSetterMenu>}
                                                          </div>
                                                      </div>
                                                  </div>
                                              ]}
                                              fakeValue={'Overlays'}/>


                                </div> : null}
                        </div>
                        {this.state.type && !this.state.noData && !this.state.error ? <div className={'graph-top-wrapper'}>
                            {!this.state.noData ?
                                <KPI type={this.props.type} hideCustomKpis={this.state.hideCustomKpis}
                                     item={this.props.item}
                                     data={this.state.ready && this.state.kpiData ? this.state.kpiData : null}/> :
                                <p/>}
                            {/*<div className={'row reverse'} style={{alignItems: 'center', flex: '0'}}>*/}

                            {/*</div>*/}
                        </div> : null}
                        {this.state.ready ? noData : ''}
                        <div ref={this.parentRef}
                             className={'graph-holder ' + (this.state.visibleItems?.length && this.state.visibleItems?.length < detachedLimit ? ' detached' : '')}
                             onMouseLeave={this.handleMouseOut}
                             onMouseMove={this.state.visibleItems?.length && this.state.visibleItems?.length < detachedLimit ? this.handleMouseMove : null}>
                            {loader && <div className={'loader-wrapper'}>
                                {loader}
                            </div>}
                            <div
                                className={'graph ' + graphClass + (this.state.ready && this.props.loaded ? '' : ' inactive')}
                                id={"graph"}
                            />
                            {this.state.graph === 'line' && this.state.type === 'historic' && this.state.zoomData ?
                                <div onClick={this.resetZoom}
                                     className={'zoom-reset-button'}>
                                    <Icon size={11}
                                          icon={'FaRedo'}/>
                                    <div className={'text'}>Reset
                                        Zoom
                                    </div>

                                </div> : null}
                        </div>
                    </div>
                    {legend}
                </div>
            </div>
        )
    }
}

export default DesktopDataPage;