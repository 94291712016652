import React from 'react';
import './TopNav.scss';
import '../styles/_theme.scss'
import Logout from "../components/Logout";
import BreadcrumbList from "../components/BreadcrumbList";
import gaiaLogo from '../images/gaia.png';
import {Icon, Button, GlobalSearch, Loader, CardBody, Toast, Menu} from 'gd-react';
import LMenu from '../components/Menu/Menu'
import GridDuck from "gridduck";
import history from "../meta/history"
import AddManualDeviceModal from "../modals/AddDevice/AddManualDeviceModal";
import AddSiteModal from "../modals/AddSite/AddSiteModal";
import AddHubModal from "../modals/AddHub/AddHubModal";
import AddOverviewGroupHubModal from "../modals/AddOverviewGroup/AddOverviewGroupModal";
import AddGroupModal from "../modals/AddGroup/AddGroupModal";
import AddMemberModal from "../modals/AddMember/AddMemberModal";
import AddFileModal from "../modals/AddFile/AddFileModal";
import AddApplicationModal from "../modals/AddApplication/AddApplicationModal";
import AddPartnerModal from "../modals/AddPartner/AddPartnerModal";
import AddEditCustomFieldTypeModal from "../modals/AddEditCustomField/AddEditCustomFieldType";
import {Tooltip} from "@material-ui/core";
import BreadcrumbService from "../meta/breadcrumb-service";
import AddElectralinkDeviceModal from "../modals/AddDevice/AddElectralinkDeviceModal";
import TariffModal from "../pages/TariffModal";
import AddInvoiceModal from "../modals/AddInvoice/AddInvoiceModal";
import cookie from "react-cookies";
import applyTheme from "../services/generate-theme";
import ThemePicker from "./ThemePicker";
import {Portal} from 'react-portal';
import GdModal from "../components/GdModal/GdModal";
import IconSearch from "../components/IconSearch/IconSearch";
import NotificationsPopover from "../components/Notifications/NotificationsPopover";
import AddAutomationModal from "../modals/Automation/AddAutomationModal";
import NotificationsDrawer from "../components/Notifications/NotificationsDrawer";
import DisconnectedPopover from "../components/Disconnected/DisconnectedPopover";
import AddDataDownloadModal from "../modals/AddDataDownload/AddDataDownloadModal";
import DisconnectedDrawer from "../components/Disconnected/DisconnectedDrawer";
import CreateReportScheduleModal from "../modals/CreateReportSchedule/CreateReportScheduleModal";
import * as _ from "lodash";
import NotificationsButton from "./NotificationsButton";
import ContextBar from "../components/ContextBar";
import {addCookie, getCookie} from "../services/cookieManager";
import IssuePopover from "../components/IssuePopover/IssuePopover";
import CreateDataUpload from "../pages/dataUpload/CreateDataUpload";

const themes = {
    light: {
        icon: 'FaSun',
        title: 'Light'
    },
    dark: {
        icon: 'FaMoon',
        title: 'Dark'
    }
}
const toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            .replace('-', '')
            .replace('_', '');
    });
};

class TopNav extends React.Component {
    componentDidMount() {
        this.notificationAudio = new Audio('/sounds/GD-Notification.mp3');
        this.errorAudio = new Audio('/sounds/GD-Disconnection.mp3');
    }

    revertToNonPartnerLogin() {
        let existingAccessToken = cookie.load('existingAccessToken');
        console.log("Existing access token", existingAccessToken);
        let accessToken = {
            expires: existingAccessToken.expires,
            token: existingAccessToken.token,
            mfaRequired: existingAccessToken.mfaRequired,
            partnerOverride: existingAccessToken.partnerOverride,
            scope: existingAccessToken.scope,
            tokenType: existingAccessToken.tokenType,
            user: {id: existingAccessToken.user.id}
        };
        delete accessToken.scope;
        cookie.remove('existingAccessToken');
        cookie.save('accessToken', accessToken, {path: '/'});
        GridDuck.setAccessToken({
            accessToken
        });
        history.push("/")
        window.location.reload();
    }

    constructor(props) {
        super(props);
        this.revertToNonPartnerLogin = this.revertToNonPartnerLogin.bind(this);
        this.globalSearch = this.globalSearch.bind(this);
        this.onSelectFromSearch = this.onSelectFromSearch.bind(this);
        this.updateAccountInfo = this.updateAccountInfo.bind(this);
        this.getSearchShortcuts = this.getSearchShortcuts.bind(this)
        this.updateModalState = this.updateModalState.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.openNotifications = this.openNotifications.bind(this)
        this.openNotificationsDrawer = this.openNotificationsDrawer.bind(this)
        this.openGaiaDrawer = this.openGaiaDrawer.bind(this)
        this.openDisconnected = this.openDisconnected.bind(this)
        this.openDisconnectedDrawer = this.openDisconnectedDrawer.bind(this)
        this.loadTheme = this.loadTheme.bind(this);
        this.incidentsChanged = this.incidentsChanged.bind(this);
        // this.handleKeyPress = this.handleKeyPress.bind(this);
        let self = this;

        this.searchOffset = 0
        this.state = {
            openAddManualDeviceModal: false,
            openAddElectralinkDeviceModal: false,
            openAddSiteModal: false,
            openAddOverviewGroupModal: false,
            openAddHubModal: false,
            openAddGroupModal: false,
            openAddAutomationModal: false,
            openAddAlertModal: false,
            openAddDataDownloadModal: false,
            openCreateReportScheduleModal: false,
            openAddMemberModal: false,
            openTariffModal: false,
            openAddInvoiceModal: false,
            openAddDataUploadModal: false,
            openAddPartnerModal: false,
            openAddCustomFieldTypeModal: false,
            openAddApplicationModal: false,
            openAddFileModal: false,
            globalSearchValue: null,
            darkLightMode: 'light',
            accentPalette: 'default',
            accentPalettes: null,
            showThemePicker: false,
            incidentCount: null,
            notificationsCount: null,
            notificationsTypes: null,
            notificationsAnchorEl: null,
            disconnectedAnchorEl: null,
            openDisconnectedOnTab: null,
            openDisconnectedDrawer: getCookie('disconnectionDrawerOpen', 'true'),
            openGaiaDrawer: getCookie('gaiaDrawerOpen', 'true'),
            openNotificationsDrawer: getCookie('notificationDrawerOpen', 'true'),
            allGroup: null
        };


        Promise.all([
            GridDuck.getAccount({id: GridDuck.userId})
        ])
            .then(function (results) {
                self.user = results[0];
                let darkLight = self.user.darkLight ? self.user.darkLight : 'light'
                let theme = self.user.theme ? self.user.theme : 'default'
                window.$exampleAccount = results[0].username === 'example@gridduck.com';
                self.user.on('updated', self.updateAccountInfo);
                // self.incidentsChanged();
                let customTheme;
                if (self.user.customTheme) {
                    customTheme = {
                        light: {},
                        dark: {}
                    }

                    // self.user.customTheme
                    for (let snake in self.user.customTheme.dark) {
                        customTheme.dark[toCamel(snake)] = self.user.customTheme.dark[snake];
                    }
                    for (let snake in self.user.customTheme.light) {
                        customTheme.light[toCamel(snake)] = self.user.customTheme.light[snake];
                    }
                }

                let defaultItem = self.user.themes.find(t => t.code === 'default');
                let defaultIndex = self.user.themes.findIndex(t => t.code === 'default');

                if (self.user.whiteLabel && self.user.whiteLabelCustomTheme && self.user.whiteLabelTheme && Object.keys(self.user.whiteLabelTheme).length) {
                    window._companyName = self.user.wlOrganisationName || self.user.organisationName;
                    console.log(self.user.whiteLabelTheme, ' : white label theme');
                    window._defaultThemeOverride = {
                        code: 'default',
                        name: self.user.organisationName,
                        palette: {
                            dark: _.mapKeys(self.user.whiteLabelTheme.dark, (v, k) => _.camelCase(k)),
                            light: _.mapKeys(self.user.whiteLabelTheme.light, (v, k) => _.camelCase(k)),
                        }
                    }
                }

                if (window._defaultThemeOverride && defaultItem) {
                    self.user.themes.splice(defaultIndex, 1)
                    self.user.themes.unshift(window._defaultThemeOverride);
                }

                self.setState({
                    user: self.user,
                    darkLightMode: darkLight,
                    accentPalette: theme,
                    accentPalettes: self.user.themes,
                    customTheme: customTheme,
                    hasEditPermission: (self.user.orgPermission === 'edit' || self.user.orgPermission === 'admin' || self.user.orgPermission === 'owner')
                }, () => {
                    self.loadTheme(darkLight, theme);
                });
            });
        GridDuck.getNotifications({
                items: 1,
                filters: [
                    {field: 'new', value: true}
                ]
            }
        ).then((notifications) => {
            self.notifications = notifications;
            self.notifications.on('updated', self.incidentsChanged);
            self.incidentsChanged();
        });

        Promise.all([
            GridDuck.getIssues({
                items: 1,
                filters: [
                    {
                        field: 'unignored',
                        value: true
                    }
                ]
            }),
            GridDuck.getIgnoredDevices({
                items: 1
            })
        ]).then(function (results) {
            let incidents = results[0];
            let ignoredDevices = results[1];
            self.incidents = incidents;
            self.incidents.on('updated', self.incidentsChanged);
            self.ignoredDevices = ignoredDevices;
            self.ignoredDevices.on('updated', self.incidentsChanged);
            self.incidentsChanged();
        });
    }

    goToPage(page) {
        history.push("/" + page);
    }

    updateModalState(stateName, value) {
        this.state[stateName] = value;
        this.state.newCustomField = null;
        this.setState(this.state);
    }

    componentWillUnmount() {
        if (this.user && this.user.off) {
            this.user.off('updated', this.updateAccountInfo);
        }
    }

    incidentsChanged() {
        let self = this;
        // if (self.updatingIncidents) {
        // 	return;
        // }
        self.updatingIncidents = true;
        let oldNotificationCount = this.state.notificationsCount;
        let oldIncidentsCount = this.state.incidentCount;
        if (this.user && this.incidents && this.notifications) {
            let incidentCount = parseInt(this.incidents.total);
            let ignoredDevicesCount = parseInt(this.ignoredDevices.total);
            let incidents = [];
            let notificationsCount = 0;
            // let notifications = [];
            let notificationsTypes = [];
            this.notifications.list
                .forEach(function (notification) {
                        console.log('checking ', notification)
                        if (notification.category) {
                            let category = (notification.category === 'create' || notification.category === 'delete' || notification.category === 'update') ? 'info' : notification.category;
                            if (notificationsTypes.indexOf(category) === -1) {
                                notificationsTypes.push(category)
                            }
                            // notificationsCount++;
                        }
                        // notifications.push(notification)
                        // notification.on('updated', self.incidentsChanged);
                    }
                );
            notificationsCount = parseInt(this.notifications.total);
            // this.incidents.list
            //     .filter(i => !i.timeEnd)
            //     .filter(i => i.objectName && i.objectName.length)
            //     .filter(i => this.user.ignoredSites.indexOf(i.siteId) === -1 && this.user.ignoredAssets.indexOf(i.objectId) === -1)
            //     .forEach(function (incident) {
            //         incidentCount++;
            //         incidents.push(incident);
            //         // if (incident.inferredAssetIds) {
            //         //     incidentCount += incident.inferredAssetIds.length;
            //         // }
            //     })
            console.log(incidents, ' incidents being set')

            this.setState({
                incidentCount: incidentCount,
                ignoredDevicesCount: ignoredDevicesCount,
                incidents: incidents,
                notificationsCount: notificationsCount,
                notifications: this.notifications,
                notificationsTypes: notificationsTypes,
                ignoredDevices: self.ignoredDevices ? self.ignoredDevices.list : [],
                incidentsLoaded: true
            }, function () {
                if (self.state.notificationsCount > 0 && self.state.notificationsCount !== oldNotificationCount && oldNotificationCount !== null && self.state.notificationsCount > oldNotificationCount) {
                    console.log("play");
                    if (self.notificationAudio && (!self.lastPlayed || new Date().getTime() - self.lastPlayed > 5000) && !this.user.muteNotificationSound) {
                        self.notificationAudio.play();
                        self.lastPlayed = new Date().getTime();
                    }
                    self.setState({animateNotifications: true}, function () {
                        setTimeout(function () {
                            self.setState({animateNotifications: false});
                        }, 400);
                    });
                }
                if (self.state.incidentCount > 0 && self.state.incidentCount !== oldIncidentsCount && oldIncidentsCount !== null) {
                    console.log("play error");
                    // if (self.errorAudio && (!self.lastPlayedError || new Date().getTime() - self.lastPlayedError > 5000) && !this.user.muteNotificationSound) {
                    // 	console.log("do play error");
                    // 	self.errorAudio.play();
                    // 	self.lastPlayedError = new Date().getTime();
                    // }
                    self.setState({animateErrors: true}, function () {
                        setTimeout(function () {
                            self.setState({animateErrors: false});
                        }, 400);
                    });
                }
                self.updatingIncidents = false;
            });
        }
    }

    updateAccountInfo() {
        this.incidentsChanged();
        this.setState({user: this.user});
    }

    openNotifications(e) {
        if (this.state.incidentsLoaded) {
            this.setState({notificationsAnchorEl: e.currentTarget})
        }
    }

    openNotificationsDrawer() {
        // this.markNotificationsSeen();
        if (this.state.incidentsLoaded) {
            addCookie('notificationDrawerOpen', 'true');
            this.setState({openNotificationsDrawer: true})
        }
    }

    openDisconnected(e) {
        this.setState({disconnectedAnchorEl: e.currentTarget})
    }

    openDisconnectedDrawer(tab) {
        addCookie('disconnectionDrawerOpen', 'true');
        if (this.props.updateFunctions.updateDisconnectedDrawer) this.props.updateFunctions.updateDisconnectedDrawer({openDisconnectedDrawer: true})
        // this.setState({openDisconnectedDrawer: true, openDisconnectedOnTab: tab})
    }

    openGaiaDrawer(tab) {
        addCookie('gaiaDrawerOpen', 'true');
        if (this.props.updateFunctions.updateDisconnectedDrawer) this.props.updateFunctions.updateDisconnectedDrawer({openGaiaDrawer: true});
        // this.setState({openDisconnectedDrawer: true, openDisconnectedOnTab: tab})
    }

    async onSelectFromSearch(ev, item) {
        let self = this;
        console.log('global search on change has been triggered', item)
        if (!item) return

        this.setState({
            globalSearchValue: ''
        }, () => {
            this.setState({globalSearchValue: null});
        });

        let recentItem = {
            itemType: item.itemType,
            itemId: item.id
        };
        await GridDuck.createSearchHistory(recentItem)

        let string;
        switch (item.itemType) {
            case 'site':
                BreadcrumbService.siteGroup = null;
                string = "/site/" + item.id
                history.push(string);
                break;
            case 'asset':
                BreadcrumbService.siteGroup = null;
                history.push("/device/" + item.id);
                break;
            case 'gateway':
                BreadcrumbService.siteGroup = null;
                history.push("/hub/" + item.id);
                break;
            case 'group':
                BreadcrumbService.siteGroup = null;
                history.push("/group/" + item.id);
                break;
            case 'timeswitch':
                BreadcrumbService.siteGroup = null;
                history.push("/timeswitch/" + item.id);
                break;
            case 'rule':
                BreadcrumbService.siteGroup = null;
                history.push("/automations/" + item.id);
                break;
            case 'siteGroup':
                BreadcrumbService.siteGroup = null;
                string = "/siteGroup/" + item.id
                history.push(string);
                break;
            case 'tariff':
                BreadcrumbService.siteGroup = null;
                history.push("/tariffs/" + item.id);
                break;
            case 'invoice':
                BreadcrumbService.siteGroup = null;
                history.push("/invoices");
                break;
            case 'report':
                BreadcrumbService.siteGroup = null;
                history.push("/reports/" + item.id);
                break;
            case 'location':
                BreadcrumbService.siteGroup = null;
                history.push("/settings/organisation/locations/" + item.id);
                break;
            default:
                console.log(item, 'item lacking support');
        }
    }

    generateDisplayData(type) {
        let icon = 'FaHome', subtitle;
        switch (type) {
            case 'site':
                icon = 'FaBuilding';
                subtitle = 'Site'
                break;
            case 'asset':
                icon = 'FaPlug';
                subtitle = 'Device'
                break;
            case 'gateway':
                icon = 'MdRouter';
                subtitle = 'Hub'
                break;
            case 'group':
                icon = 'FaCubes';
                subtitle = 'Group'
                break;
            case 'timeswitch':
                icon = 'FaClock';
                subtitle = 'Timeswitch'
                break;
            case 'rule':
                icon = 'FaTasks';
                subtitle = 'Rule'
                break;
            case 'siteGroup':
                icon = 'FaGlobeEurope';
                subtitle = 'Site Group'
                break;
            case 'tariff':
                icon = 'FaMoneyBillWave';
                subtitle = 'Tariff'
                break;
            case 'invoice':
                icon = 'FaFileInvoice';
                subtitle = 'Invoice'
                break;
            case 'report':
                icon = 'FaChartArea';
                subtitle = 'Report'
                break;
            case 'location':
                icon = 'FaMapMarkerAlt';
                subtitle = 'Address'
                break;
        }
        return {
            icon: icon,
            subtitle: subtitle
        }
    }

    async globalSearch(search, reset) {
        let self = this
        if (reset) this.searchOffset = 0
        let limit = 25
        console.log('trying to search ', search)
        let res = await GridDuck.getSearchs({
            filters: [
                {
                    field: 'query',
                    value: search
                }
            ],
            offset: this.searchOffset,
            items: limit
        })
        let results = res.list.filter((item) => item.name).map((item) => {
            let displayData = this.generateDisplayData(item.type)
            return ({
                id: item.id,
                itemType: item.type,
                title: item.name,
                subtitle: displayData.subtitle,
                icon: displayData.icon
            });
        })
        let newOffset = self.searchOffset + limit;
        self.searchOffset = newOffset
        return Promise.resolve(results)
    }

    async getSearchShortcuts() {
        let filters = []
        let res = await GridDuck.getSearchHistorys({filters: filters})
        let shortcuts = res.list.filter((item) => item.name).map((r) => {
            let data = this.generateDisplayData(r.itemType)
            return {
                id: r.itemId,
                title: r.name,
                subtitle: data.subtitle,
                itemType: r.itemType,
                icon: 'GiAnticlockwiseRotation',
                type: 'Recent'
            }
        })
        this.setState({searchShortcuts: shortcuts})
    }

    changeBaseURL(value) {
        GridDuck.config.baseUrl = value;
        cookie.save('baseUrl', value, {path: '/'})
        window.location.reload();
    }

    loadTheme(theme, accentPalette, customPalette) {
        let self = this;
        if (this.state.accentPalettes && this.state.accentPalettes.length) {
            let palette = accentPalette && this.state.accentPalettes.find(ap => ap.code === accentPalette) ? this.state.accentPalettes.find(ap => ap.code === accentPalette).palette : null;
            let cp = this.state.customTheme || customPalette || this.state.accentPalettes.find(ap => ap.code === 'default').palette;
            if (accentPalette === 'custom') {
                palette = cp;
            }
            applyTheme({
                theme: theme,
                accentPalette: accentPalette,
                accentPalettePalette: palette
            }, themes, this.state.accentPalettes, (t, ap) => {
                let userUpdateObject = {
                    darkLight: t,
                    theme: ap,
                    customTheme: cp
                };
                self.user.set(userUpdateObject);
                this.setState({darkLightMode: t, accentPalette: ap, customTheme: cp});
            });
        }
    }

    render() {
        let self = this;
        let matches, acronym;
        if (this.state.user) {
            // let imageStyle = {
            //     backgroundImage: `url(${this.state.user.profilePictureThumbnail})`
            // }

            matches = this.state.user.name.match(/\b(\w)/g); // ['J','S','O','N']
            acronym = matches.join(''); // JSON

        }

        let ppicMenuItems = [
            {
                icon: {
                    color: 'gd-grey',
                    name: 'FaCog',
                    size: '12'
                },
                label: 'Settings',
                onMenuItemClick: function () {
                    history.push("/settings");
                }
            }, {
                icon: {
                    color: 'gd-grey',
                    name: this.state.darkLightMode === 'light' ? 'FaMoon' : 'FaSun',
                    size: '12'
                },
                label: this.state.darkLightMode === 'light' ? 'Dark Mode' : 'Light Mode',
                onMenuItemClick: () => this.loadTheme(this.state.darkLightMode === 'light' ? 'dark' : 'light', this.state.accentPalette)
            }, {
                icon: {
                    color: 'gd-grey',
                    name: 'FaPaintRoller',
                    size: '12'
                },
                label: 'Theme',
                onMenuItemClick: function () {
                    self.setState({showThemePicker: true});
                }
            }, {
                icon: {
                    color: 'gd-grey',
                    name: 'FaUsers',
                    size: '12'
                },
                label: 'Organisation',
                onMenuItemClick: function () {
                    history.push("/organisation");
                }
            }, {
                icon: {
                    color: 'gd-grey',
                    name: 'FaLaptopCode',
                    size: '12'
                },
                label: 'Developer',
                onMenuItemClick: function () {
                    history.push("/developer");
                }
            }
        ];

        if (this.state.user && this.state.user.god) {
            ppicMenuItems.push({
                icon: {
                    color: 'gd-grey',
                    name: 'FaPrayingHands',
                    size: '12'
                },
                label: 'Admin Console',
                onMenuItemClick: function () {
                    history.push("/admin-console");
                }
            })
            ppicMenuItems.push({
                icon: {
                    color: 'gd-grey',
                    name: 'FaGlobeEurope',
                    size: '12'
                },
                label: 'API (' + GridDuck.config.baseUrl + ')',
                subMenu: {
                    depth: 1,
                    header: {
                        title: 'URLs'
                    },
                    items: [
                        {
                            label: 'Production v2',
                            onMenuItemClick: function () {
                                self.changeBaseURL('https://v2.api.gridduck.com/');
                            },
                            icon: {
                                color: 'gd-grey',
                                name: 'FaGlobeEurope',
                                size: '12'
                            }
                        },
                        {
                            label: 'Production v2 [HTTP2]',
                            onMenuItemClick: function () {
                                self.changeBaseURL('https://v21.api.gridduck.com/');
                            },
                            icon: {
                                color: 'gd-grey',
                                name: 'FaGlobeEurope',
                                size: '12'
                            }
                        },
                        {
                            label: 'Staging v2',
                            onMenuItemClick: function () {
                                self.changeBaseURL('https://staging2.api.gridduck.com/');
                            },
                            icon: {
                                color: 'gd-grey',
                                name: 'FaGlobeEurope',
                                size: '12'
                            }
                        },
                        {
                            label: 'Localhost',
                            onMenuItemClick: function () {
                                self.changeBaseURL('http://localhost:3000/');
                            },
                            icon: {
                                color: 'gd-grey',
                                name: 'FaGlobeEurope',
                                size: '12'
                            }
                        },
                        {
                            label: 'Beta',
                            onMenuItemClick: function () {
                                self.changeBaseURL('https://beta.api.gridduck.com/');
                            },
                            icon: {
                                color: 'gd-grey',
                                name: 'FaGlobeEurope',
                                size: '12'
                            }
                        }
                    ]
                }
            })
        }

        ppicMenuItems.push({
            icon: {
                color: 'gd-grey',
                name: 'FaPowerOff',
                size: '12'
            },
            label: 'Logout',
            onMenuItemClick: function () {
                GridDuck.logout();
            }
        })

        let newItems = [];
        if (this.state.user && (this.state.user.orgPermission === 'admin' || this.state.user.orgPermission === 'owner')) {
            newItems = [
                {
                    type: 'divider',
                    title: 'Portfolio'
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaPlug',
                        size: '12'
                    },
                    disabled: this.user.noSites ? {
                        message: 'Create a site to add a device',
                        position: 'left'
                    } : false,
                    label: 'Device',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddManualDeviceModal', true)
                    }
                }, {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaSquare',
                        size: '12'
                    },
                    label: 'Hub',
                    disabled: this.user.noSites ? {
                        message: 'Create a site to add a hub',
                        position: 'left'
                    } : false,
                    onMenuItemClick: function () {
                        self.updateModalState('openAddHubModal', true)
                    }
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaBuilding',
                        size: '12'
                    },
                    label: 'Site',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddSiteModal', true)
                    }
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaGlobeEurope',
                        size: '12'
                    },
                    disabled: this.user.noSites ? {
                        message: 'Add a site to create an site group',
                        position: 'left'
                    } : false,
                    label: 'Site Group',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddOverviewGroupModal', true)
                    }
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaCubes',
                        size: '12'
                    },
                    disabled: this.user.noAssets ? {
                        message: 'Add a device to create a device group',
                        position: 'left'
                    } : false,
                    label: 'Device Group',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddGroupModal', true)
                    }
                },
                {
                    type: 'divider',
                    title: 'Behaviour'
                }, {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaBell',
                        size: '12'
                    },
                    label: 'Alert',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddAlertModal', true)
                    }
                }, {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaRobot',
                        size: '12'
                    },
                    label: 'Automation',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddAutomationModal', true)
                    }
                },
                {
                    type: 'divider',
                    title: 'Data Export'
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaFileInvoice',
                        size: '12'
                    },
                    disabled: this.user.noSites ? {
                        message: 'Add a site to create an invoice',
                        position: 'left'
                    } : this.user._orgPermission !== 'owner' && this.user._orgPermission !== 'admin' ? {
                        message: 'You do not have permission to create invoices',
                        position: 'left'
                    } : null,
                    label: 'Invoice',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddInvoiceModal', true)
                    }
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaChartArea',
                        size: '12'
                    },
                    disabled: this.user.noAssets ? {
                        message: 'Add a device to create a rule',
                        position: 'left'
                    } : false,
                    label: 'Report Schedule',
                    onMenuItemClick: function () {
                        self.updateModalState('openCreateReportScheduleModal', true)
                    }
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaFileDownload',
                        size: '12'
                    },
                    label: 'Data Download',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddDataDownloadModal', true)
                    }
                },
                // {
                //     icon: {
                //         color: 'gd-grey',
                //         name: 'FaFileUpload',
                //         size: '12'
                //     },
                //     label: 'Data Upload',
                //     onMenuItemClick: function () {
                //         self.updateModalState('openAddDataUploadModal', true)
                //     }
                // },
                {
                    type: 'divider',
                    title: 'Organisation'
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaUsers',
                        size: '12'
                    },
                    label: 'Organisation Member/s',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddMemberModal', true)
                    }
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaHandshake',
                        size: '12'
                    },
                    label: 'Partner Organisation',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddPartnerModal', true)
                    }
                },
                {
                    type: 'divider',
                    title: 'Other'
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'FaMoneyBillWave',
                        size: '12'
                    },
                    disabled: this.user.noSites ? {
                        message: 'Add a site to create an tariff',
                        position: 'left'
                    } : false,
                    label: 'Tariff',
                    onMenuItemClick: function () {
                        self.updateModalState('openTariffModal', true)
                    }
                },
                {
                    icon: {
                        color: 'gd-grey',
                        name: 'MdPlaylistAdd',
                        size: '14'
                    },
                    label: 'Custom Field',
                    onMenuItemClick: function () {
                        self.updateModalState('openAddCustomFieldTypeModal', true)
                    }
                }
            ];
        }
        if (this.state.user && this.state.user.features && this.state.user.features.indexOf('ELECTRALINK') !== -1)
            newItems.splice(2, 0, {
                icon: {
                    color: 'gd-grey',
                    name: 'FaTachometerAlt',
                    size: '12'
                },
                disabled: this.user.noSites ? {
                    message: 'Create a site to add a meter',
                    position: 'left'
                } : false,
                label: 'Electricity Meter',
                onMenuItemClick: function () {
                    self.updateModalState('openAddElectralinkDeviceModal', true)
                }
            });
        let accessToken = cookie.load('accessToken');
        let existingAccessToken = cookie.load('existingAccessToken');
        return (
            [(
                <div id="top_nav" key={"top-nav-div"}>
                    <BreadcrumbList {...this.props}/>
                    {this.state.user ? <GlobalSearch
                        getShortcuts={self.getSearchShortcuts}
                        shortcuts={self.state.searchShortcuts}
                        value={this.state.globalSearchValue}
                        // inputValue={this.state.globalSearchValue ? this.state.globalSearchValue : ''}
                        onChange={this.onSelectFromSearch}
                        getList={this.globalSearch}/> : ''}
                    {this.state.user && this.state.user.username !== 'example@gridduck.com' && this.state.hasEditPermission ?
                        <div style={{height: '30px'}}><LMenu key="LMenu" controlFromElement={<div key="NewButton"
                                                                                                  className={'new-button'}>
                            <Button key="Button" label={'+ New'}/></div>
                        }
                                                             menuItems={newItems}/></div> : ''}
                    {self.user ? <NotificationsButton
                        notificationsCount={this.state.notificationsCount}
                        notificationsTypes={this.state.notificationsTypes}
                        incidentsLoaded={this.state.incidentsLoaded}
                        onClick={this.openNotifications}
                    /> : null}
                    {self.user ? <IssuePopover header
                                               updateDisconnectedDrawer={this.props.updateFunctions.updateDisconnectedDrawer}></IssuePopover> : null}

                    {/*    <div*/}
                    {/*        className={'top-nav-icon disconnected ' + (this.state.incidentCount ? ' activated' : '') + (this.state.animateErrors ? ' animate-glow-zoom' : '')}*/}
                    {/*        onClick={this.state.incidentsLoaded ? this.openDisconnectedDrawer : null}>*/}
                    {/*        {this.state.incidentCount ?*/}
                    {/*            <div className={'not-dot'}>{this.state.incidentCount}</div> : null}*/}
                    {/*        <div className={'icon-w'}>*/}
                    {/*            {this.state.incidentsLoaded ? <Icon*/}
                    {/*                    icon={'FaExclamationCircle'}*/}
                    {/*                    color={this.state.incidentCount ? 'gd-red' : 'gd-grey'} size={'14'}/> :*/}
                    {/*                <Loader type={'circular'} size={15}/>}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Tooltip>*/}
                    {/*{self.user ? <Tooltip*/}
                    {/*    title={'Switch to ' + (this.state.darkLightMode === 'light' ? 'dark ' : 'light ') + 'mode'}>*/}
                    {/*    <div className={'top-nav-icon'}><Icon*/}
                    {/*        onIconClick={() => this.loadTheme(this.state.darkLightMode === 'light' ? 'dark' : 'light', this.state.accentPalette)}*/}
                    {/*        icon={this.state.darkLightMode === 'light' ? 'FaMoon' : 'FaSun'}*/}
                    {/*        color={'gd-grey'} size={'13'}/></div>*/}
                    {/*</Tooltip> : null}*/}


                    {/*TODO Add back in for E-Commerce*/}
                    {/*<Tooltip title={'Your Orders'}><div className={'top-nav-icon'}><Icon icon={'FaShoppingCart'}*/}
                    {/*                                                                     color={'gd-grey'} size={'16'}/></div></Tooltip>*/}
                    {/*{!accessToken.partnerOverride ? (<div onClick={this.revertToNonPartnerLogin}>LOGGED IN AS PARTNER. REVERT TO OLD</div>) : ''}*/}
                    {this.state.user && this.state.user.god ? <Tooltip title={'AI Assistant'}>
                        <div onClick={this.openGaiaDrawer} className={'top-nav-icon gaia-logo-no-txt'}><img alt={'gaia'}
                                                                                                            src={gaiaLogo}/>
                        </div>
                    </Tooltip> : null}
                    {this.state ?
                        <Menu menuHeader={{
                            title: this.state.user ? this.state.user.name : <Loader/>,
                            subtitle: this.state.user ? this.state.user.username : <Loader/>
                        }} controlFromElement={<Tooltip title={this.state.user ? 'Settings' : 'Loading account'}>
                            <div className={'profile-image'} style={{
                                backgroundImage: 'url("' + (this.state.user ? this.state.user.profilePictureThumbnail : '') + '")',
                                color: 'white',
                                fontWeight: '700',
                                fontSize: '12px',
                                textAlign: 'center',
                                lineHeight: '26px'
                            }}>{this.state.user ? this.state.user.profilePictureThumbnail ? '' : acronym :
                                <Loader type={'circular'} size={12}/>}</div>
                        </Tooltip>} menuItems={ppicMenuItems}/>
                        : <div className={'profile-image'} style={{
                            borderColor: 'grey'
                        }}/>}

                    {this.state.showSupportModal ? <GdModal open={this.state.showSupportModal}
                                                            contentLabel={'Add Site'}
                                                            title={'Support Contacts'}
                                                            onClose={() => this.setState({showSupportModal: false})}>
                        <CardBody>
                            <div style={{marginBottom: '20px'}}>
                                <p><strong>General</strong></p>
                                <p style={{fontSize: '11px', marginBottom: '10px'}}>For any general questions about any
                                    possible collaborations or just to say hi.</p>
                                <p><a href={'mailto:hello@gridduck.com'}>hello@gridduck.com</a></p>
                            </div>
                            <div style={{marginBottom: '20px'}}>
                                <p><strong>Sales</strong></p>
                                <p style={{fontSize: '11px', marginBottom: '10px'}}>To purchase hardware or for any
                                    other
                                    sales related queries.</p>
                                <p><a href={'mailto:sales@gridduck.com'}>sales@gridduck.com</a></p>
                            </div>
                            <div>
                                <p><strong>Tech Support</strong></p>
                                <p style={{fontSize: '11px', marginBottom: '10px'}}>For any technical support, whether
                                    it
                                    the installation of hardware or the usage of the dashboard.</p>
                                <p><a href={'mailto:support@gridduck.com'}>support@gridduck.com</a></p>
                            </div>

                        </CardBody>
                    </GdModal> : ''}
                    {this.state.openAddManualDeviceModal ?
                        <AddManualDeviceModal open={this.state.openAddManualDeviceModal}
                                              onClose={() => this.updateModalState('openAddManualDeviceModal', false)}/> : ''}
                    {this.state.openNotificationsDrawer ?
                        <NotificationsDrawer
                            notifications={this.state.notifications}
                            account={self.user}
                            open={this.state.openNotificationsDrawer}
                            onClose={() => this.updateModalState('openNotificationsDrawer', false)}/> : ''}
                    {/*{this.state.openDisconnectedDrawer ?*/}
                    {/*    <DisconnectedDrawer*/}
                    {/*        open={this.state.openDisconnectedDrawer}*/}
                    {/*        account={this.user}*/}
                    {/*        issuesCount={this.state.incidentCount}*/}
                    {/*        ignoredDevicesCount={this.state.ignoredDevicesCount}*/}
                    {/*        openOnTab={this.state.openDisconnectedOnTab}*/}
                    {/*        onClose={() => this.updateModalState('openDisconnectedDrawer', false)}/> : ''}*/}
                    {this.state.openAddElectralinkDeviceModal ?
                        <AddElectralinkDeviceModal open={this.state.openAddElectralinkDeviceModal}
                                                   onClose={() => this.updateModalState('openAddElectralinkDeviceModal', false)}/> : ''}
                    {this.state.openAddSiteModal ? <AddSiteModal open={this.state.openAddSiteModal}
                                                                 onClose={() => this.updateModalState('openAddSiteModal', false)}/> : ''}
                    {this.state.openAddHubModal ? <AddHubModal open={this.state.openAddHubModal}
                                                               onClose={() => this.updateModalState('openAddHubModal', false)}/> : ''}
                    {this.state.openAddOverviewGroupModal ?
                        <AddOverviewGroupHubModal open={this.state.openAddOverviewGroupModal}
                                                  onClose={() => this.updateModalState('openAddOverviewGroupModal', false)}/> : ''}
                    {this.state.openAddGroupModal ? <AddGroupModal open={this.state.openAddGroupModal}
                                                                   onClose={() => this.updateModalState('openAddGroupModal', false)}/> : ''}
                    {this.state.openAddAutomationModal || this.state.openAddAlertModal ?
                        <AddAutomationModal type={this.state.openAddAlertModal ? 'alert' : null}
                                            open={this.state.openAddAutomationModal || this.state.openAddAlertModal}
                                            onClose={() => this.updateModalState(this.state.openAddAutomationModal ? 'openAddAutomationModal' : 'openAddAlertModal', false)}/> : ''}
                    {this.state.openAddMemberModal ? <AddMemberModal open={this.state.openAddMemberModal}
                                                                     onClose={() => this.updateModalState('openAddMemberModal', false)}/> : ''}

                    {this.state.openAddDataDownloadModal ?
                        <AddDataDownloadModal open={this.state.openAddDataDownloadModal}
                                              onClose={() => this.updateModalState('openAddDataDownloadModal', false)}/> : ''}

                    {this.state.openCreateReportScheduleModal ?
                        <CreateReportScheduleModal open={this.state.openCreateReportScheduleModal}
                                                   onClose={() => this.updateModalState('openCreateReportScheduleModal', false)}/> : ''}

                    {this.state.openTariffModal ? <TariffModal onSave={(message) => this.setState({showToast: message})}
                                                               open={this.state.openTariffModal}
                                                               onClose={() => this.updateModalState('openTariffModal', false)}/> : ''}
                    {this.state.openAddPartnerModal ? <AddPartnerModal open={this.state.openAddPartnerModal}
                                                                       onClose={() => this.updateModalState('openAddPartnerModal', false)}/> : ''}
                    {this.state.openAddFileModal ? <AddFileModal open={this.state.openAddFileModal}
                                                                 onClose={() => this.updateModalState('openAddFileModal', false)}/> : ''}
                    {this.state.openAddCustomFieldTypeModal ?
                        <AddEditCustomFieldTypeModal loadNewItem={async (itemId) => {
                            let customFieldRes = await GridDuck.getCustomFieldTypes({
                                filters: [{
                                    field: 'id',
                                    value: itemId
                                }]
                            });
                            this.setState({newCustomField: customFieldRes.list[0]});
                            return Promise.resolve();
                        }} item={this.state.newCustomField} open={this.state.openAddCustomFieldTypeModal}
                                                     onClose={() => {
                                                         this.updateModalState('openAddCustomFieldTypeModal', false);
                                                     }}/> : ''}
                    {this.state.openAddApplicationModal ? <AddApplicationModal open={this.state.openAddApplicationModal}
                                                                               onClose={() => this.updateModalState('openAddApplicationModal', false)}/> : ''}
                    {this.state.openAddInvoiceModal ? <AddInvoiceModal open={this.state.openAddInvoiceModal}
                                                                       onClose={() => this.updateModalState('openAddInvoiceModal', false)}/> : ''}
                    {this.state.openAddDataUploadModal ? <CreateDataUpload open={this.state.openAddDataUploadModal}
                                                                           reloadItem={async (id) => {
                                                                               let dataUpload = await GridDuck.getDataUpload({id: id});
                                                                               this.setState({openAddDataUploadModal: dataUpload});
                                                                               // if (window.location.pathname.indexOf('data-management/uploads') === -1) {
                                                                               //     this.updateModalState('openAddDataUploadModal', false)
                                                                               // }
                                                                               history.push('/settings/organisation/data-management/uploads?id=' + dataUpload.id + '&alreadyOpen=true');
                                                                           }}
                                                                           item={this.state.openAddDataUploadModal}
                                                                           onClose={() => {
                                                                               history.push('/settings/organisation/data-management/uploads');
                                                                               this.updateModalState('openAddDataUploadModal', false)
                                                                           }}/> : ''}
                    {this.state.notificationsAnchorEl ? <NotificationsPopover
                        notifications={this.state.notifications}
                        account={this.user}
                        openNotificationsDrawer={this.openNotificationsDrawer}
                        onClose={() => {
                            // TODO turn back on for deployment
                            if (this.state.notifications && this.state.notifications.list && this.state.notifications.list.length) this.state.notifications.list[0].set({seen: true});
                            this.setState({notificationsAnchorEl: null});
                        }}
                        anchorEl={this.state.notificationsAnchorEl}/> : null}

                    {this.state.disconnectedAnchorEl ? <DisconnectedPopover
                        openDisconnectedDrawer={this.openDisconnectedDrawer}
                        incidents={this.state.incidents}
                        onClose={() => this.setState({disconnectedAnchorEl: null})}
                        anchorEl={this.state.disconnectedAnchorEl}/> : null}

                    <Toast onClose={() => this.setState({showToast: false})} message={this.state.showToast}
                           open={!!(this.state.showToast)}
                           severity="success"
                           anchorOrigin={{
                               vertical: 'bottom',
                               horizontal: 'left',
                           }}/>
                    {this.state.showThemePicker && self.user ?
                        <Portal node={document && document.getElementById('decorated_page')}>
                            <ThemePicker userTheme={self.state.customTheme}
                                         selectTheme={(t) => self.loadTheme(self.state.darkLightMode, t.code)}
                                         updateCustomTheme={(ct) => self.loadTheme(self.state.darkLightMode, 'custom', ct.palette)}
                                         theme={this.state.accentPalette} darkLight={this.state.darkLightMode}
                                         themes={this.state.accentPalettes}
                                         closePicker={() => this.setState({showThemePicker: false})}/>
                        </Portal> : null}
                </div>), (
                <div key="contextBar" id={'contextBar'}/>
            )]
        );
    }
}

export default TopNav;
