import React from "react";
import {Menu} from '@material-ui/core'
import {Icon, Dropdown, Button} from "gd-react";
import GridDuck from 'gridduck'
import GenericLoader from "./GenericLoader";
import history from "../meta/history";

class LegendMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            loading: true,
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    async handleClick(e) {
        let self = this;
        let item = this.props.item;
        console.log(item, ' : item');
        self.setState({
            anchorEl: e ? e.currentTarget : this.state.anchorEl,
            loading: true,
        });
        if (this.props.graphType === 'site_breakdown') {
            let res = await GridDuck.getSite({id: item.id})
            self.setState({loading: false, site: res})
        } else {
            let deviceRes = await GridDuck.getAsset({id: item.id})
            self.setState({
                loading: false,
                device: deviceRes
            });
        }
    }

    handleClose() {
        this.setState({anchorEl: null, loading: true, device: null});
    }

    generateQuery() {
        let query = 'data?';
        let dateRange, graphType, graph = '', combined = '', average = '';
        let currentQuery = window.location.search;
        if (/dR=.*?(?=\W)/g.exec(currentQuery)) {
            dateRange = /dR=.*?(?=\W)/g.exec(currentQuery)
        }
        if (/&ty=.*?(?=\W)/g.exec(currentQuery)) {
            graphType = /&ty=.*?(?=\W)/g.exec(currentQuery)
        }
        if (/&g=.*?(?=\W)/g.exec(currentQuery)) {
            graph = /&g=.*?(?=\W)/g.exec(currentQuery)
        }
        if (/&c=.*?(?=\W)/g.exec(currentQuery)) {
            combined = /&c.*?(?=\W)/g.exec(currentQuery)
        }
        if (/&avg=.*?(?=\W)/g.exec(currentQuery)) {
            average = /&avg.*?(?=\W)/g.exec(currentQuery)
        }

        //conditions to account for =>

        //device breakdown -> device
        //     daterange (preserved), UNKNOWN graphtype, no combined, no average

        if (graphType) {
            if (~graphType.indexOf('ty=breakdown')) {
                query += dateRange
            }

            //site breakdown -> site
            //     daterange (preserved), UNKNOWN graphtype, UNKNOWN combined,

            if (~graphType.indexOf('ty=site_breakdown')) {
                query += dateRange
            }

            //device line graph -> device
            //     daterange (preserved), probably also line graph, no combined, no average

            //device line graph -> site
            //     daterange (preserved), probably also line graph, combined = false, average (preserved as with daterange)

            if (~graphType.indexOf('ty=historic')) {
                query += dateRange + graphType
            }
        }

        return query
    }

    render() {
        this.generateQuery()
        let device = this.state.device;
        let site = this.state.site
        let links = [];
        if (device) {
            if (this.props.itemType !== 'device') {
                links.push(<div
                    key="NoDevice"
                    onClick={() => {
                        let query = this.generateQuery()
                        let url = '/device/' + device.id + '/' + query
                        history.push(url)
                    }}
                    className={'row'}>
                    <div className={'column'}>
                        <div className={'legend-title'}>Go to Device</div>
                        <div className={'legend-subtitle'}>{device.name}</div>
                    </div>
                    <div className={'legend-menu-icon-container'}>
                        <Icon
                            size={'14'}
                            icon={'FaChevronCircleRight'}/>
                    </div>
                </div>)
            }
            if (this.props.itemType !== 'site' && device.siteId) {
                links.push(<div
                    key="NoSite"
                    onClick={() => {
                        let query = this.generateQuery()
                        history.push('/site/' + device.siteId + '/' + query);
                    }}
                    className={'row'}>
                    <div className={'column'}>
                        <div className={'legend-title'}>Go to Site</div>
                        <div className={'legend-subtitle'}>{device.siteName}</div>
                    </div>
                    <div className={'legend-menu-icon-container'}>
                        <Icon size={'14'} icon={'FaChevronCircleRight'}/>
                    </div>
                </div>)
            }
            if (device.gatewayId) {
                links.push(<div
                    key="Hub"
                    onClick={() => {
                        history.push('/hub/' + device.gatewayId);
                    }}
                    className={'row'}>
                    <div className={'column'}>
                        <div className={'legend-title'}>Go to Hub</div>
                        <div className={'legend-subtitle'}>{device.gatewayName}</div>
                    </div>
                    <div className={'legend-menu-icon-container'}>
                        <Icon size={'14'} icon={'FaChevronCircleRight'}/>
                    </div>
                </div>)
            }
        } else if (site) {
            //site breakdown
            links.push(<div
                key="Site"
                onClick={() => {
                    let search = window.location.search.replace('site_breakdown', 'historic')
                    let url = '/site/' + site.id + '/data' + search
                    console.log(url, 'url being pushed')
                    history.push(url);
                }}
                className={'row'}>
                <div className={'column'}>
                    <div className={'legend-title'}>Go to Site</div>
                    <div className={'legend-subtitle'}>{site.name}</div>
                </div>
                <div className={'legend-menu-icon-container'}>
                    <Icon size={'14'} icon={'FaChevronCircleRight'}/>
                </div>
            </div>)
        }
        return (
            <div className={'legend-menu'}>
                <div style={{position: 'relative', overflow: 'hidden'}}
                     onClick={this.handleClick}>
                    {this.props.children}
                </div>
                <Menu
                    // id="legend-menu"
                    id="filter-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                    transformOrigin={{vertical: "top", horizontal: "right"}}
                    anchorEl={this.state.anchorEl}
                    open={!!(this.state.anchorEl)}
                    disableEnforceFocus
                    onClose={this.handleClose}
                    PaperProps={{
                        style: {
                            transform: 'translateX(-5%)',
                        }
                    }}
                >
                    <div
                        className={'legend-menu-container kpi-tooltip'}
                    >
                        <div className={'legend-header'}>
                            {this.props.item.name}
                        </div>
                        {this.state.loading ? <GenericLoader/> : links}
                    </div>
                </Menu>
            </div>
        )
    }
}

export default LegendMenu
