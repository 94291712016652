import React from "react";
import {Icon, Input, Loader, Dropdown, StateOptions, ScheduleTimeline, ScheduleSetter, Button} from "gd-react";
import "../Rules.scss";
import GridDuck from 'gridduck';
import history from "../../meta/history";
import moment from "moment";

class RulePageTimeswitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assetNames: [],
            showDeleteModal: false
        };
        this.formatTime = this.formatTime.bind(this)
        let self = this;

        if (props.rule.actions) {
            let assetsAction = props.rule.actions.find((a) => a.assets)
            let promises = assetsAction?.assets?.map(a => GridDuck.getAsset({id: a}));
            if (promises) Promise.all(promises).then(function (results) {
                self.setState({
                    assetIds: props.rule.actions[1].assets,
                    assetNames: results.map((a, i) => <span style={{marginLeft: (i !== 0) ? '8px' : '0'}}
                                                            className={'link'}
                                                            onClick={() => history.push('/device/' + a.id)}>{a.name}{(i !== results.length - 1) ? ',' : ''}</span>)
                });
            })

        }
    }

    componentDidMount() {
        let isSpecific = !!this.props.rule.actions?.find((action) => action.type === 'TIME')
        let isLegacy = (this.props.rule.triggers && this.props.rule.triggers.length)
        this.setState({
            specificSchedule: isSpecific,
            legacy: isLegacy
        });
    }

    formatTime(unix) {
        return moment(unix).format("YYYY-MM-DDTHH:mm")
    }

    isDifferentAssets(oldAssets, newAssets) {
        let isDifferent = false
        if (oldAssets && newAssets) {
            if (!newAssets.every((value, index) => value === oldAssets[index]) || !oldAssets.every((value, index) => value === newAssets[index]))
                isDifferent = true
        }
        return isDifferent
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let self = this;
        if (this.props.rule.actions && this.props.rule.actions.length) {
            let assetAction = this.props.rule.actions.find(a => a.assets);
            let assetActionPrev = prevProps.rule.actions.find(a => a.assets);
            if (assetAction && assetActionPrev && this.isDifferentAssets(assetActionPrev.assets, assetAction.assets)) {
                let promises = assetAction.assets.map(a => GridDuck.getAsset({id: a}));
                Promise.all(promises).then(function (results) {
                    self.setState({
                        assetNames: results.map(a => a.name),
                        assetIds: results.map(a => a.id)
                    });
                })
            }
        }
    }

    render() {
        console.log(this.props.rule, ' rule in porps')
        let self = this
        let miniDisplay;
        let standardDisplay;
        let stateOptions = []
        StateOptions.forEach(function (stateOption) {
            stateOption.options.forEach(function (option) {
                if (!option.nonSelectable && !stateOptions.find(sO => sO.value === option.state)) {
                    stateOptions.push({
                        value: option.state,
                        title: option.label,
                        color: option.color
                    });
                }
            });
        });
        let dateDisplay;
        if (this.state.specificSchedule) {
            let timeActions = this.props.rule.actions.filter((s) => s.type === 'TIME')
            timeActions.sort((a, b) => a.startTime - b.startTime);
            let rows = timeActions.map((s) => {
                let activeAction = this.props.rule.actions.find((a) => a.id === s.activeAction)
                let state = activeAction.stateType
                return <div
                    className={'row'}
                    style={{marginTop: '10px'}}
                >
                    <div className={'date-cell'}>
                        <label>Start</label>
                        <Input
                            type="datetime-local"
                            disabled
                            id="meeting-time"
                            name="meeting-time"
                            value={this.formatTime(s.startTime)}
                        />
                    </div>
                    <div className={'date-cell'}>
                        <label>End</label>
                        <Input
                            type="datetime-local"
                            disabled
                            error={this.state.triedToProgress && this.errors['time']}
                            id="meeting-time"
                            name="meeting-time"
                            value={this.formatTime(s.endTime)}
                        />
                    </div>
                    <div className={'date-cell'}>
                        <label>State</label>
                        <Dropdown
                            style={{marginTop: '15px'}}
                            nolabel='true'
                            disabled
                            value={state}
                            fixeditems={stateOptions}/>
                    </div>
                </div>
            })
            dateDisplay = <div>
                {rows}
            </div>
        } else {
            let scheduleAction = this.props.rule.actions.find((action) => action.isSchedule)
            let rules = scheduleAction ? scheduleAction.schedules?.map((s) => {
                let activeAction = this.props.rule.actions.find((a) => a.id === s.state)
                let state = activeAction.stateType
                return {
                    ...s,
                    state: state
                }
            }) : []
            dateDisplay = <div
                style={{marginTop: '10px'}}
            >
                {rules && rules.length ? <ScheduleTimeline
                    inactiveState={this.props.rule.inactiveState}
                    rules={rules}
                    activeState={this.props.rule.actions && this.props.rule.actions[2] ? this.props.rule.actions[2].stateType : this.props.rule.alerts[0].type}/> : null}
                {rules && rules.length && !this.props.strippedback ? <ScheduleSetter
                    inactiveState={this.props.rule.inactiveState}
                    rules={rules}
                /> : null}
            </div>
        }
        console.log(this.state.legacy, ' legacy view rule')
        if (this.props.strippedback) {
            miniDisplay = <div className={'mini-wrapper'}>
                {this.props.rule.name ? <p className={'rule-title'}>{this.props.rule.name}</p> : null}
                <p >{this.props.rule.description ? this.props.rule.description : ' - '}</p>
                <div className={'link-wrapper'} >
                    <Button label={"View Rule"} color={'gd-grey'}
                            onClick={() => history.push("/automations/" + this.props.rule.id)}/>
                </div>
                {!this.state.legacy ? <div className={'row'} style={{marginTop: '15px'}}>
                    <Dropdown
                        label={'Default State'}
                        disabled
                        value={this.props.rule.inactiveState}
                        fixeditems={stateOptions}/>
                </div> : null}
                {this.state.legacy ? <p>This format of rule is no longer supported - please contact GridDuck Support at <a
                    href={'mailto:support@gridduck.com'}>support@gridduck.com</a></p> : null}
                {!this.state.legacy && !this.state.specificSchedule ? <div className={"timeswitch-preview"}>
                        {dateDisplay}
                    </div>
                    : null}
                <div className={'divider'}/>
            </div>
        } else {
            standardDisplay = <div>{this.props.rule.description ? <p>{this.props.rule.description}</p> : null}
                {this.state.legacy ? <p>This format of rule is no longer supported - please contact GridDuck Support at <a
                    href={'mailto:support@gridduck.com'}>support@gridduck.com</a></p> : null}
                {!this.state.legacy ? <div className={"timeswitch-preview"}>
                        <div className={'row'} style={{marginTop: '15px'}}>
                            <Dropdown
                                label={'Default State'}
                                disabled
                                value={this.props.rule.inactiveState}
                                fixeditems={stateOptions}/>
                        </div>
                        {dateDisplay}
                    </div>
                    : null}
                {!this.state.legacy ? this.state.assetNames.length ?
                    <div className={"timeswitch-actions"}>
                        <p>Applied to:</p>
                        <div className={"action"}>
                        {this.state.assetNames}
                        </div>
                    </div>
                    : <div className={"timeswitch-actions"}>
                        <p>Applied to:</p>
                        <Loader type={'circular'}
                                size={20}
                        />
                    </div> : null}
            </div>
        }

        return (
            <div className={"details"}>
                <div className={"details-inner"}>
                    {miniDisplay}
                    {standardDisplay}
                </div>
            </div>
        )
    }
}

export default RulePageTimeswitch;
