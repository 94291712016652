import React from "react";
import {Dropdown} from "gd-react";
import "./GraphRangePicker.scss";

class GraphTypePicker extends React.PureComponent {
    constructor(props) {
        super(props);

        this.setType = this.setType.bind(this);
        this.setGraphType = this.setGraphType.bind(this);
        this.setAverageType = this.setAverageType.bind(this);
        this.setCombined = this.setCombined.bind(this);
    }

    setCombined(e) {
        this.props.onChange({
            graph: this.props.graph,
            type: this.props.type,
            time: this.props.time,
            average: this.props.average,
            combined: e.target.value
        });
    }

    setType(e) {
        let type = e.target.value;
        let graph = this.props.graph;
        if (type === 'historic_bar') {
            graph = 'bar';
            type = 'historic';
        }
        if (type.indexOf('breakdown') !== -1) {
            graph = 'bar';
        }
        if (type === 'historic_line') {
            console.log('set to line because no bar option');
            graph = 'line';
            type = 'historic';
        }
        if (type === 'heatmap' || type === 'hour_of_day' || type === 'day_of_week') {
            graph = 'bar';
        }
        let switchToType;
        if (this.props.dataType.dataTypeSibling && (this.props.dataType.dataType === 'instantaneous' && graph === 'bar') || (this.props.dataType.dataType === 'aggregate' && graph === 'line')) {
            switchToType = this.props.dataType.dataTypeSibling.toLowerCase();
            console.log(switchToType, ' : switch to type');
        }
        this.props.onChange({
            graph: graph,
            type: type,
            time: this.props.time,
            average: this.props.average,
            combined: this.props.combined,
            switchToType: switchToType
        });
    }

    setGraphType(e) {
        this.props.onChange({
            graph: e.target.value,
            type: this.props.type,
            time: this.props.time,
            average: this.props.average,
            combined: this.props.combined
        });
    }

    setAverageType(e) {
        this.props.onChange({
            graph: this.props.graph,
            type: this.props.type,
            time: this.props.time,
            average: e.target.value,
            combined: this.props.combined
        });
    }

    render() {
        console.log("props in picker", this.props.dataType);
        let avgOptions = [
            {
                value: 'average',
                title: 'Average'
            },
            {
                value: 'max',
                title: 'Maximum'
            },
            {
                value: 'min',
                title: 'Minimum'
            }
        ];
        if (this.props.isAll) {
            avgOptions = [
                {
                    value: 'average',
                    title: 'Average'
                }
            ];
        }
        if (this.props.dRString === 'last_15_mins') {
            avgOptions = [
                {
                    value: 'average',
                    title: 'Average'
                }
            ];
        }

        console.log(this.props.tabs, ' : tabs in graphTypePicker');
        let typeOptions = [];
        let hasSiblingTab = (this.props.dataType.dataTypeSibling && this.props.dataTabs.find(t => t.dataType.type === this.props.dataType.dataTypeSibling));
        if (this.props.dataType.dataType === 'instantaneous' || hasSiblingTab) {
            typeOptions.push({
                value: 'historic_line',
                title: 'Line Graph',
                icon: 'FaChartLine',
                color: 'gd-grey'
            })
        }
        if (this.props.dataType.dataType === 'aggregate' || hasSiblingTab) {
            typeOptions.push({
                value: 'historic_bar',
                title: 'Bar Graph',
                icon: 'FaChartBar',
                color: 'gd-grey'
            })
            if ((this.props.filterType === 'site_id' || this.props.filterType === 'site_group_id' || this.props.filterType === 'group_id' || this.props.filterType === 'org_partner_id') && !this.props.isAll) {
                typeOptions.push(
                    {
                        value: 'breakdown',
                        title: 'Device Breakdown',
                        icon: 'FaPlug',
                        color: 'gd-grey'
                    }
                );
                typeOptions.push(
                    {
                        value: 'category_breakdown',
                        title: 'Category Breakdown',
                        icon: 'AiOutlineAppstoreAdd',
                        color: 'gd-grey'
                    }
                );
            }
            if ((this.props.filterType === 'site_group_id' || this.props.filterType === 'org_partner_id') && !this.props.isAll) {
                typeOptions.push(
                    {
                        value: 'site_breakdown',
                        title: 'Site Breakdown',
                        icon: 'FaBuilding',
                        color: 'gd-grey'
                    }
                );
            }
            typeOptions.push(
                {
                    value: 'hour_of_day',
                    title: 'Hour of Day Average',
                    icon: 'FaClock',
                    color: 'gd-grey'
                }
            );
            typeOptions.push(
                {
                    value: 'day_of_week',
                    title: 'Day of Week Average',
                    icon: 'FaCalendar',
                    color: 'gd-grey'
                },
            )
            typeOptions.push(
                {
                    value: 'heatmap',
                    title: 'Heatmap',
                    icon: 'FiGrid',
                    color: 'gd-grey'
                },
            )
        }
        let averageDropdown;
        let aggregateDropdown;
        if (((this.props.category === 'Electricity' || this.props.category === 'Gas' || this.props.category === 'Water' || this.props.category === 'Hot Water' || this.props.category === 'Generation') && this.props.type === 'historic' && this.props.graph === 'line') || (this.props.category !== 'Electricity' && this.props.category !== 'Gas' && this.props.category !== 'Water' && this.props.category !== 'Hot Water' && this.props.category !== 'Generation')) {
            averageDropdown = (
                <Dropdown nolabel='true'
                          key={'av-dd'}
                          borderless
                          onChange={this.setAverageType}
                          fixeditems={avgOptions}
                          value={this.props.average}/>
            )
            if (this.props.filterType === 'site_id' || this.props.filterType === 'site_group_id' || this.props.filterType === 'group_id' || this.props.filterType === 'org_partner_id') {
                let aggregateOptions = [
                    {
                        title: 'Total',
                        value: true
                    }

                ];
                if ((this.props.filterType !== 'site_group_id' && this.props.filterType !== 'org_partner_id') || (this.props.filterId && this.props.filterId.substr(0, 3) !== 'all')) {
                    aggregateOptions.push({
                        title: 'By Device',
                        value: false
                    });
                }

                aggregateDropdown = (
                    <Dropdown nolabel='true'
                              key={'ag-dd'}
                              borderless
                              onChange={this.setCombined}
                              fixeditems={aggregateOptions}
                              value={this.props.combined}/>
                )
            }
        }
        let typeValue = this.props.type;
        if (this.props.type === 'historic') {
            if (this.props.graph === 'line') {
                console.log('Load line because inst');
                typeValue = 'historic_line'
            }
            if (this.props.graph === 'bar') {
                console.log('Load line because agg');
                typeValue = 'historic_bar'
            }
        }
        if (this.props.dataType === 'instantaneous') {
            if (!this.props.graph || this.props.graph === 'bar') {
                typeValue = 'historic_line'
            }
        } else if (this.props.dataType === 'aggregate') {
            if (!this.props.graph || this.props.graph === 'line') {
                typeValue = 'historic_bar'
            }
        }
        if (!typeOptions.find(t => t.value === typeValue)) {
            this.setType({target: typeOptions[0]})
        }

        return (
            <div className={"graph-type-picker"}>
                <Dropdown nolabel='true' onChange={this.setType}
                          key={'ty-dd'}
                          borderless
                          fixeditems={typeOptions} value={typeValue}/>
                {this.props.type === 'breakdown' ? (
                        <Dropdown nolabel='true'
                                  borderless
                                  key={'hist-dd'}
                                  onChange={(e) => this.props.onChange({
                                      graph: this.props.graph,
                                      type: this.props.type,
                                      time: e.target.value
                                  })}
                                  fixeditems={[
                                      {
                                          value: 'historic',
                                          title: 'Historic'
                                      },
                                      {
                                          value: 'realtime',
                                          title: 'Real-time'
                                      }
                                  ]}
                                  value={this.props.time}/>)
                    : ''}

                {/*{(this.props.category === 'Electricity' || this.props.category === 'Gas' || this.props.category === 'Water' || this.props.category === 'Hot Water' || this.props.category === 'Generation') && this.props.type === 'historic' ? (*/}
                {/*    <Dropdown className={"graph-type"} nolabel='true' onChange={this.setGraphType}*/}
                {/*              key={'lg-dd'}*/}
                {/*              fixeditems={[*/}
                {/*                  {*/}
                {/*                      value: 'line',*/}
                {/*                      title: 'Line Graph'*/}
                {/*                  },*/}
                {/*                  {*/}
                {/*                      value: 'bar',*/}
                {/*                      title: 'Bar Graph'*/}
                {/*                  },*/}
                {/*              ]} value={this.props.graph}/>) : ''}*/}
                {aggregateDropdown}
                {averageDropdown}
            </div>
        )
    }

}

export default GraphTypePicker;
