import {Icon} from 'gd-react';

const React = require("react");

export const generateUtilityTypeCell = function (t) {
    let icon = 'FaBolt';
    let color = '#FFCA00';
    let name = 'Electricity';
    let utilityType = t?.utilityType;

    if (utilityType) {
        if (utilityType.includes('GAS')) {
            icon = 'FaFire';
            color = '#D768F2';
            name = utilityType.includes('GAS_ENERGY') ? 'Gas Energy' : 'Gas Volume';
        }
        if (utilityType.includes('WATER')) {
            icon = 'FaWater';
            color = '#00C3EE';
            name = 'Water';
        }
        if (utilityType.includes('HOT_WATER')) {
            icon = 'FaWater';
            color = '#f52ec0';
            name = 'Hot Water';
        }
    }
    return (
        <div className={'row'} style={{display: 'flex', alignItems: 'center'}}>
            <div style={{
                height: '20px',
                width: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: color,
                borderRadius: '10px'
            }}>
                {icon ? <Icon size={'10'} icon={icon} color={'white'}/> : null}
            </div>
            <span style={{marginLeft: '10px'}}>{name}</span>
        </div>

    )
}

export const generateTariffTypeCell = function (t) {
    let icon = 'FaMoneyBillWaveAlt';
    let color = '#39b025';
    let name = 'Spend';

    if (t.utilityType?.includes('CARBON')) {
        icon = 'FaCloud';
        color = '#4b4b4b';
        name = 'Carbon';
    }
    return (
        <div className={'row'} style={{display: 'flex', alignItems: 'center'}}>
            <div style={{
                height: '20px',
                width: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: color,
                borderRadius: '10px'
            }}>
                {icon ? <Icon size={'10'} icon={icon} color={'white'}/> : null}
            </div>
            <span style={{marginLeft: '10px'}}>{name}</span>
        </div>

    )
}

export const addStandingChargesFromRange = function (tariffs, rangeStart, rangeEnd) {
    // Filter tariffs to those that have any overlap with the specified range
    const secondsInDay = 86400;
    let reducedTariffs = tariffs.reduce((overlaps, tariff) => {
        const overlapStart = Math.max(tariff.contractStart, rangeStart);
        const overlapEnd = Math.min(tariff.contractEnd, rangeEnd);
        const overlapRangeDays = Math.floor((overlapEnd - overlapStart) / secondsInDay);
        const rangeDays = Math.floor((rangeEnd - rangeStart) / secondsInDay);

        // Check if there's a valid overlap where relevant, if not just return full range with tariff
        if ((overlapStart < overlapEnd && tariff.applyContractRange) || !tariff.applyContractRange) {
            overlaps.push({
                id: tariff.id,
                standingChargeDays: tariff.applyContractRange ? overlapRangeDays : rangeDays
            });
        }

        return overlaps;
    }, []);
    if (reducedTariffs && reducedTariffs.length) {
        return reducedTariffs;
    } else return null;
}