import React from "react";
import "./KPI.scss";
import {Icon, Tooltip} from "gd-react";
import SomeSitesWithoutIconWithTooltip from "./SomeSitesWithoutIconWithTooltip";
import TariffBreakdown from "./TariffBreakdown";
import InteractiveTooltip from "./InteractiveTooltip";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import {Navigation} from "swiper";
import GridDuck from 'gridduck';
import CustomKpiMenu from "./customKpiMenu";
import CustomKPI from "./customKPI";
import GenericLoader from "./GenericLoader";
import formatter from "../services/formatter";
import AddEditCustomFieldTypeModal from "../modals/AddEditCustomField/AddEditCustomFieldType";

class KPI extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            customKpis: [],
            hideCustomKpis: false,
            showCustomFieldTypeModal: false
        };
        this.generateKPI = this.generateKPI.bind(this);
        this.customFieldSelected = this.customFieldSelected.bind(this);
        this.updateCustomKPIs = this.updateCustomKPIs.bind(this);
        this.carouselRef = React.createRef();
        this.customKpiMenuRef = React.createRef();
    }

    componentDidMount() {
        let self = this;
        GridDuck.getPreferences({id: GridDuck.userId}).then((preferences) => {
            self.preferences = preferences;
            self.setState({customKpis: preferences.customKpiDefaults});
        });
    }

    generateKPI(type, datum) {
        let self = this;
        if (type === 'total') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Total Usage</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'average' || type.indexOf('Avg') !== -1) {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Usage</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'max') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Usage</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'min') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Usage</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'totalDemand') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Total Demand</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'change') {
            return (<Tooltip key={type + "-tooltip"} label={'Compared to: ' + datum.subdata}
                             position={'bottom'}>
                    <div key={type} className={"kpi-datum"}>
                        <div className={"kpi-title"}>Change</div>
                        <div className={"kpi-value"}>{datum.value}</div>
                    </div>
                </Tooltip>
            )
        } else if (type === 'temperatureAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Temperature</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'temperatureMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Temperature</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'temperatureMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Temperature</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'humidityAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Humidity</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'humidityMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Humidity</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'humidityMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Humidity</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'lightAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Light</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'lightMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Light</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'lightMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Light</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'consumptionAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Demand</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'consumptionMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Demand</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'exported_consumptionMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Rate</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'consumptionMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Demand</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'exported_consumptionMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Rate</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
            //     some change
        } else if (type === 'currentAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Current</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'currentMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Current</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'currentMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Current</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'asset_signal_strengthAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Signal Strength</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'asset_signal_strengthMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Signal Strength</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'asset_signal_strengthMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Signal Strength</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'asset_battery_voltageAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average Battery Voltage</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'asset_battery_voltageMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max Battery Voltage</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'asset_battery_voltageMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min Battery Voltage</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'gateway_wifi_strengthAvg') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Average WiFi Signal Strength</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'gateway_wifi_strengthMax') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Max WiFi Signal Strength</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if (type === 'gateway_wifi_strengthMin') {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Min WiFi Signal Strength</div>
                    <div className={"kpi-value"}>{datum.value}</div>
                </div>
            )
        } else if ((type === 'carbon') && datum.totalValues.length && datum.totalValues.filter((tv, i) => (tv.value || tv.value === 0)).length) {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Carbon</div>
                    <div className={'row'} style={{flex: '0 0'}}>
                        {datum.noTariff ? <div className={"kpi-value"}>No Tariff</div> : ''}
                        {!datum.noTariff && datum.totalValues && datum.totalValues.length ? datum.totalValues.map((tv, i) => {
                            if ((tv.value || tv.value === 0)) {
                                let _total = tv.value / 1000;
                                return <div key={i + 'kpi-v'} className={"kpi-value"}>{i > 0 ?
                                    <span>+</span> : ''}{formatter('comma')(Math.round(_total))}kg</div>
                            } else return null;
                        }) : ''}
                        {!datum.noTariff && datum.sitesWithoutTariffs && datum.sitesWithoutTariffs.length ?
                            <SomeSitesWithoutIconWithTooltip size={'20'} urlItem={'tariffs'}
                                                             buttonLabel={'Manage Tariffs'}
                                                             sitesWithout={datum.sitesWithoutTariffs}
                                                             title={'a Tariff'}/> : ''}
                    </div>
                </div>
            )
        } else if ((type === 'spend') && datum.totalValues.length && datum.totalValues.filter((tv, i) => (tv.value || tv.value === 0)).length) {
            return (
                <div key={type} className={"kpi-datum"}>
                    <div className={"kpi-title"}>Spend</div>
                    <div className={'row'} style={{flex: '0 0'}}>
                        {datum.noTariff ? <div className={"kpi-value"}>No Tariff</div> : ''}
                        {!datum.noTariff && datum.totalValues && datum.totalValues.length ? datum.totalValues.map((tv, i) => {
                            if ((tv.value || tv.value === 0)) {
                                let _total = tv.value + tv.standingChargeValue;
                                if (this.props.type === 'asset') {
                                    _total = tv.value;
                                    return <div key={i + 'kpi-v'} className={"kpi-value"}>{i > 0 ?
                                        <span>+</span> : ''}{formatter('currency')(tv.currency).format((_total) / 100)}</div>
                                } else {
                                    return <InteractiveTooltip key={'data-tt'}
                                                               title={<TariffBreakdown type={this.props.type}
                                                                                       value={tv}/>}>
                                        <div key={i + 'kpi-v'} className={"kpi-value"}>{i > 0 ?
                                            <span>+</span> : ''}{formatter('currency')(tv.currency).format((_total) / 100)}</div>
                                    </InteractiveTooltip>
                                }
                            } else return null;
                        }) : ''}
                        {!datum.noTariff && datum.sitesWithoutTariffs && datum.sitesWithoutTariffs.length ?
                            <SomeSitesWithoutIconWithTooltip size={'20'} urlItem={'tariffs'}
                                                             buttonLabel={'Manage Tariffs'}
                                                             sitesWithout={datum.sitesWithoutTariffs}
                                                             title={'a Tariff'}/> : ''}
                    </div>
                </div>
            )
        } else return null;
    }

    customFieldSelected(customField, comparative) {
        let self = this;
        let customKpis = this.state.customKpis.concat([
            {
                id: customField.id,
                comparative: comparative
            }
        ]);
        this.updateCustomKPIs(customKpis);
    }

    updateCustomKPIs(customKpis, customFieldType, comparative) {
        let self = this;
        if (customKpis) {
            this.setState({
                customKpis: customKpis
            }, () => {
                self.preferences.set({
                    customKpiDefaults: customKpis
                });
            });
        } else {
            if (customFieldType && comparative) {
                let newCustomKPis = self.state.customKpis;
                newCustomKPis.forEach(ck => {
                    if (ck.id === customFieldType.id) {
                        ck.comparative = comparative;
                    }
                })
                self.preferences.set({
                    customKpiDefaults: newCustomKPis
                });
            }
        }
        let carousel = this.carouselRef.current ? this.carouselRef.current.swiper : null;
        if (carousel) {
            window.setTimeout(() => {
                carousel.update();
            }, 100)
        }
    }

    removeCustomField(customFieldId) {
        let customKpis = this.state.customKpis.filter((ckpi) => {
            return ckpi.id !== customFieldId
        });
        this.updateCustomKPIs(customKpis);
    }

    render() {
        let hideCustomKpis;
        let kpiDatas = [];
        let self = this;
        let className = "kpi";
        if (this.props.disabled) {
            className += " disabled";
        }
        if (this.props.data) {
            Object.keys(this.props.data)
                .filter(k => k !== null)
                .forEach(function (key, i) {
                    let kpiDom = self.generateKPI(key, self.props.data[key]);
                    let swiperDom = <SwiperSlide key={'s' + key + i} style={{width: 'auto'}}>{kpiDom}</SwiperSlide>;
                    kpiDatas.push(swiperDom);
                });
        }
        let carousel = this.carouselRef.current ? this.carouselRef.current.swiper : null;
        return (
            <Swiper
                ref={this.carouselRef}
                navigation={true} modules={[Navigation]}
                className={className}
                spaceBetween={0}
                slidesPerView={'auto'}>
                {this.props.data ? kpiDatas : null}
                {this.props.data && kpiDatas.length && this.state.customKpis.length && !this.props.hideCustomKpis ? this.state.customKpis.map((ckpi, i) => {
                    return <SwiperSlide key={ckpi.id + i} style={{width: 'auto'}}>
                        <CustomKPI
                            item={this.props.item}
                            type={this.props.type}
                            onComparativeChange={(customFieldType, comparative) => this.updateCustomKPIs(null, customFieldType, comparative)}
                            onLoaded={() => {
                                if (carousel) {
                                    carousel.update();
                                    carousel.slideTo(carousel.slides.length - 1, 300);
                                }
                            }} removeKpi={() => self.removeCustomField(ckpi.id)}
                            kpiTotals={self.props.data}
                            initialComparative={ckpi.comparative}
                            customFieldId={ckpi.id}/>
                    </SwiperSlide>;
                }) : null}
                {this.props.data && kpiDatas.length && !this.props.hideCustomKpis ?
                    <SwiperSlide key={'add-new'} style={{width: 'auto'}}>
                        <CustomKpiMenu ref={this.customKpiMenuRef}
                                       showCustomFieldModal={() => this.setState({showCustomFieldTypeModal: true})}
                                       key={'custom-kpi-menu'} kpiTotals={self.props.data}
                                       onCustomFieldSelected={this.customFieldSelected}
                                       customKpis={this.state.customKpis}>
                            <div className={'custom-kpi create'}>
                                <Icon color={'grey'} size={'15'} icon={'IoMdAddCircleOutline'}/>
                                <p>Add KPI</p>
                            </div>
                        </CustomKpiMenu>
                    </SwiperSlide> : null}
                {!this.props.data ?
                    <SwiperSlide key={'loader-slide'} className={'group-kpi-loader'}
                                 style={{width: 'auto'}}><GenericLoader size={24}/></SwiperSlide> : null}
                {this.state.showCustomFieldTypeModal ?
                    <AddEditCustomFieldTypeModal loadNewItem={async (itemId) => {
                        let customFieldRes = await GridDuck.getCustomFieldTypes({
                            filters: [{
                                field: 'id',
                                value: itemId
                            }]
                        });
                        this.setState({newCustomField: customFieldRes.list[0]});
                        return Promise.resolve();
                    }} onClose={() => {
                        this.setState({showCustomFieldTypeModal: null, newCustomField: null});
                        if (this.customKpiMenuRef && this.customKpiMenuRef.current) this.customKpiMenuRef.current.handleClick();
                    }} item={this.state.newCustomField}
                                                 open={!!this.state.showCustomFieldTypeModal}/> : null}
            </Swiper>
        );
    }
}

export default KPI;