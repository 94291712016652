import React from "react";
import {Button, Card, CardBody, CardHeader, Dropdown, GdCheckbox, Input, Loader, Toast} from "gd-react";
import GridDuck from "gridduck";
import cookie from "react-cookies";
import SaveBar from "../../components/SaveBar/SaveBar";

class GatewayModbusDebugging extends React.Component {

    constructor(props) {
        super(props);
        let self = this;
        this.setSubscribing = this.setSubscribing.bind(this);
        this.transmit = this.transmit.bind(this);
        this.hubUpdated = this.hubUpdated.bind(this);

        this.state = {
            modbus_subscribe: props.item.modbusSubscribe ? true : false,
            baudRate: 9600,
            parity: 'none',
            stopBits: 2,
            dataBits: 8,
            rxTimeout: 10,
            messagesToDisplay: [
            ]
        };
        if (props.item) {
            props.item.on('updated', this.hubUpdated);
        }
    }

    hubUpdated(field, newValue) {
        if (field === 'modbusMessageRec') {
            let messageToShow = newValue
                .toUpperCase()
                .split('')
                .map((char, index) =>
                    (index % 2 === 1 ? char + ' ' : char))
                .join('')
                .trim();
            this.state.messagesToDisplay.unshift(`${new Date().toISOString()} [RX] ${messageToShow}`)
            this.setState({
                messagesToDisplay: this.state.messagesToDisplay
            });
        }
    }

    async transmit() {
        await this.props.item.setModbusParams({
            baudRate: this.state.baudRate,
            parity: this.state.parity,
            stopBits: this.state.stopBits,
            dataBits: this.state.dataBits,
            rxTimeout: this.state.rxTimeout,
        });
        let message = this.state.messageToSend.replace(/ /g, '');
        let messageToShow = message
            .toUpperCase()
            .split('')
            .map((char, index) =>
                (index % 2 === 1 ? char + ' ' : char))
            .join('')
            .trim();
        this.state.messagesToDisplay.unshift(`${new Date().toISOString()} [TX] ${messageToShow}`)
        this.setState({
            messagesToDisplay: this.state.messagesToDisplay
        });
        await this.props.item.setModbusMessage(message);
    }

    setSubscribing(subscribing) {
        if (this.props.item) {
            this.props.item.setModbusSubscribe(subscribing)
        }
        this.setState({
            modbus_subscribe: subscribing,
        })
    }

    render() {
        let self = this;
        return (
            <div className={"page grey narrow not-flex"}>
                <div className={'column top'}>
                    <div className={'detail-content'} style={{"maxWidth": "1000px"}}>
                        <Card>
                            <CardHeader title={'Transmit Messages'}/>
                            <CardBody>
                                <GdCheckbox checked={this.state.modbus_subscribe} name={'modbus_subscribe'}
                                            onChange={(e) => this.setSubscribing(e.target.checked)}
                                            label={'Modbus Debugging'}/>
                                <Input
                                    disabled={!this.state.modbus_subscribe}
                                    onChange={(e) => this.setState({baudRate: e.target.value})}
                                    type={'number'}
                                    InputProps={{step: 1}}
                                    name={'baudRate'} value={this.state.baudRate} label={'Baud Rate'}/>
                                <Input
                                    disabled={!this.state.modbus_subscribe}
                                    onChange={(e) => this.setState({parity: e.target.value})}
                                    name={'parity'} value={this.state.parity} label={'Parity'}/>
                                <Input
                                    disabled={!this.state.modbus_subscribe}
                                    onChange={(e) => this.setState({stopBits: e.target.value})}
                                    type={'number'}
                                    name={'stopBits'} value={this.state.stopBits} label={'Stop Bits'}/>
                                <Input
                                    disabled={!this.state.modbus_subscribe}
                                    onChange={(e) => this.setState({dataBits: e.target.value})}
                                    type={'number'}
                                    name={'dataBits'} value={this.state.dataBits} label={'Data Bits'}/>
                                <Input
                                    disabled={!this.state.modbus_subscribe}
                                    onChange={(e) => this.setState({rxTimeout: e.target.value})}
                                    type={'number'}
                                    name={'rxTimeout'} value={this.state.rxTimeout} label={'RX Timeout'}/>
                                <Input disabled={!this.state.modbus_subscribe}
                                       onChange={(e) => this.setState({messageToSend: e.target.value})}
                                       name={'messageToSend'} value={this.state.messageToSend} label={'Message'}/>
                                <br/>
                                <div className={'button-container'}>
                                    <Button progressRes label={'Transmit'}
                                            onClick={this.transmit}
                                            disabled={!this.state.messageToSend || this.state.messageToSend.length === 0}/>
                                </div>
                                <CardHeader title={'Modbus Log'}/>
                                <div className={'received-messages-card'}>
                                    <code>
                                        {this.state.messagesToDisplay.map((message, index) => (
                                            <div
                                                className={`modbus-message ${~message.indexOf('[TX]') ? 'sent' : 'received'}`}
                                                key={index}
                                            >
                                                {message}
                                            </div>
                                        ))}
                                    </code>
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }

}

export default GatewayModbusDebugging;
