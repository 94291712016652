import React from "react";
import './TariffForm.scss'
import Rate from './Rate'

class Rates extends React.Component {

    render() {
        console.log(this.props.rates, ' : rates');
        if (this.props.rates && this.props.rates.length && this.props.unit) {
            console.log(typeof this.props.rates)
            return this.props.rates.map((rate, index) => {
                return <Rate
                    key={rate.id}
                    id={rate.id}
                    handleTimeChange={this.props.handleTimeChange}
                    handleCostChange={this.props.handleCostChange}
                    rate={rate}
                    tariffType={this.props.tariffType}
                    disabled={this.props.disabled}
                    triedToSave={this.props.triedToSave}
                    currency={this.props.currency}
                    unit={this.props.unit}
                    all={this.props.all}
                    setAll={this.props.setAll}
                    errors={this.props.errors}
                    index={index}/>
            })
        } else return null;

    }
}

export default Rates;