import React from "react";
import "./KPIwidget.scss";
import GDGraphs from 'gd-graphs'
import GridDuck from "gridduck";
import formatter from "../../../../services/formatter";
import {Button, Icon, Toast, Tooltip} from "gd-react";
import history from "../../../../meta/history";
import TariffModal from "../../../TariffModal";
import getSpend from "../../../../services/getSpend";
import InteractiveTooltip from "../../../../components/InteractiveTooltip";
import SomeSitesWithoutIconWithTooltip from "../../../../components/SomeSitesWithoutIconWithTooltip";
import TariffBreakdown from "../../../../components/TariffBreakdown";
import GenericLoader from "../../../../components/GenericLoader";

class KPIWidget extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            total: null,
            totalSpend: '',
            spendUnit: '£',
            compareTotal: null,
            totalValues: [],
            change: '',
            upDown: '',
            ready: false,
            type: this.props.type
        };

        this.dataType = "DELIVERED";
        this.spend = false
        this.spendDataType = 'DELIVERED_SPEND';
        if (this.props.id.indexOf('gas') !== -1) {
            this.dataType += '_GAS'
            // this.dataType = 'GAS_ENERGY_DELIVERED'
            this.spendDataType = 'DELIVERED_GAS_SPEND'
        }
        if (this.props.id.indexOf('gas_energy') !== -1) {
            this.dataType = 'GAS_ENERGY_DELIVERED'
            this.spendDataType = 'GAS_ENERGY_DELIVERED_SPEND'
        }
        if (this.props.id.indexOf('water') !== -1) {
            this.dataType += '_WATER'
            this.spendDataType = 'DELIVERED_WATER_SPEND'
        }
        if (this.props.id.indexOf('hot') !== -1) {
            this.dataType += '_HOT'
            this.spendDataType = 'DELIVERED_WATER_HOT_SPEND'
        }
        if (this.props.id.indexOf('generation') !== -1) {
            this.dataType = 'GENERATED_DELIVERED';
            this.spendDataType = 'GENERATED_DELIVERED_SPEND'
        }
        if (this.props.id.indexOf('export') !== -1) {
            this.dataType = 'EXPORTED_DELIVERED';
            this.spendDataType = 'EXPORTED_DELIVERED_SPEND'
        }
        this.spendDataType = this.props.type.includes('carbon') ? this.spendDataType.replace('SPEND', 'CARBON') : this.spendDataType;
        this.fetchSpend = this.fetchSpend.bind(this);
        this.load = this.load.bind(this);
        console.log(this.spendDataType, ' : spend data type');
    }

    async fetchSpend() {
        let filterType = this.props.filterType, filterId = this.props.filterId;
        if (this.props.dataItems) {
            filterType = null;
            filterId = null;
        }
        let spendData = await getSpend(this.props.dataItems, this.props.dateData, this.spendDataType, filterType, filterId);

        console.log(spendData, ' : spendData');

        this.setState({
            ready: true,
            totalValues: spendData.totalValues,
            noTariff: spendData.noTariff,
            notFullyCovered: spendData.notFullyCovered,
            sitesWithoutTariffs: spendData.sitesWithoutTariffs
        });
    }

    assignRoute() {
        let path = window.location.pathname.split('/');
        if (path[1] === 'site') {
            history.push('/site/' + path[2] + '/tariffs');
        } else {
            history.push('/tariffs');
        }
    }

    load() {
        let self = this;
        if (this.props.type.includes('spend') || this.props.type.includes('carbon')) {
            this.spend = true
            this.fetchSpend(this.spendDataType).then(() => {
                console.log('finished');
            });
            GridDuck.getTariffs({filters: [{field: 'utilityType', value: [this.spendDataType]}]}).then((res) => {
                console.log(res.list, ' : tariffs');
                self.setState({hasTariffs: res.list && res.list.length});
            });
        } else {
            let graphParams = {
                "element": "#graph" + this.props.id,
                "timezone": "Europe/London",
                "dataTypes": [this.dataType],
                "start": this.props.dateData.start,
                "end": this.props.dateData.end,
                "granularity": this.props.dateData.end - this.props.dateData.start,
                "widget": true,
                "hidden": [],
                "showTooltip": true,
                "combined": true,
                onVisibleItems: function (visibleItems) {
                    if (!self.dead && visibleItems.length === 0) {
                        self.setState({noData: true, ready: true}, () => {
                            self.graph.remove();
                        });
                    }
                },
                error: function (err) {
                    console.log("error", err);
                    if (!self.dead) {
                        self.setState({error: true})
                    }
                },
                onReady: function () {
                    console.log("on ready");
                },
                onKPIData: function (kpiData) {
                    console.log(kpiData, ' : kpi data from generation');
                    let unit = '';
                    if (typeof kpiData.total.value === "string") {
                        unit = kpiData.total.value.replace(/[0-9]/g, '').replace('.', "")
                    }
                    if (!self.dead) {
                        self.setState({
                            total: kpiData.total ? parseFloat(kpiData.total.value) : 'No Data',
                            upDown: kpiData.change ? (kpiData.change.value.indexOf('-') !== -1 ? 'down' : 'up') : false,
                            change: kpiData.change ? kpiData.change.value.match(/\d+/g) : null,
                            spend: kpiData.spend ? kpiData.spend : null,
                            unit: unit,
                            ready: true
                        }, () => {
                            self.graph.remove();
                        });
                    }
                },
                onDefaultSelected: function (selectedItems) {
                },
                tooltipOverride: function (tooltipData) {
                    if (!self.dead) {
                        if (tooltipData && tooltipData.data && tooltipData.data[0]) self.setVal(tooltipData.data[0].value);
                    }
                },
                zoomCallback: function (zoomData) {
                }
            };

            if (this.props.filterType && this.props.filterType !== 'asset_id') {
                graphParams.filterType = this.props.filterType;
                graphParams.filterId = this.props.filterId;
            }
            if (this.props.filterType === 'asset_id' && this.props.dataItems) {
                graphParams.assets = this.props.dataItems;
            }

            if (this.props.dateData.compare_start && this.props.dateData.compare_end) {
                graphParams.compareTo = {
                    show: true,
                    start: this.props.dateData.compare_start,
                    end: this.props.dateData.compare_end
                }
            }
            this.graph = GDGraphs.barChart(graphParams);
        }


    }

    componentDidMount() {
        let self = this;
        this.load();
        let loader = document.querySelector('.kpi-loader.' + this.props.id + ' svg');
        if (loader) loader.style.color = this.props.color;
    }

    componentWillUnmount() {
        if (this.graph) this.graph.remove();
        this.dead = true;
    }

    formatCurrency(currency) {
        if (this.props.type.includes('carbon')) {
            return {
                format: (val) => {
                    return formatter('comma')(Math.round(val));
                }
            }
        } else {
            return new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: currency,
            });
        }
    }


    render() {
        const titleOverrides = {
            Generation: 'Electricity Generated',
            Export: 'Electricity Exported'
        }
        let spendRows, totalRow, self = this;
        let desiredDirection = this.props.dataType.desiredDirection || 'down';

        if (this.spend && this.state.ready) {
            spendRows = this.state.totalValues.map((v, index) => {
                let formatter = self.formatCurrency(v.currency);
                let _total = ((v.value ? v.value : 0) + v.standingChargeValue);
                if (this.props.filterType === 'asset_id') {
                    _total = (v.value ? v.value : 0);
                }
                return <div className={'kpi-widget-total-row'} key={index + 'v'}>
                    <div className={'kpi-widget-total'}>
                        {this.props.filterType !== 'asset_id' && !this.props.type.includes('carbon') ?
                            <InteractiveTooltip key={'data-tt'}
                                                title={<TariffBreakdown value={v}/>}>
                                <div style={{display: 'inline-block'}}>
                                    {(v.value || v.value === 0) || (v.standingChargeValue || v.standingChargeValue === 0) ? formatter.format(_total / 100) : 'No Data'}
                                </div>
                            </InteractiveTooltip> : <div style={{display: 'inline-block'}}>
                                {(v.value || v.value === 0) || (v.standingChargeValue || v.standingChargeValue === 0) ? formatter.format(_total / 100) : 'No Data'}
                            </div>}

                        <div className={'unit-column'}>
                            {v.percentageChange ? <div className={'percentage-changed'}>
                                <Icon icon={v.percentageDirection === 'up' ? 'FaArrowUp' : 'FaArrowDown'}
                                      size={16}
                                      color={v.percentageDirection !== desiredDirection ? 'gd-red' : 'gd-green'}/> <span
                                className={(v.percentageDirection !== desiredDirection ? 'down' : 'up')}>{v.percentageChange}%</span>
                            </div> : <div className={'kpi-widget-total-row no-cont'}/>}
                            {this.props.type.includes('carbon') ? <div className={'unit'}>
                                kg
                            </div> : null}

                        </div>
                        {self.state.notFullyCovered ?
                            <SomeSitesWithoutIconWithTooltip size={'28'}
                                                             urlItem={'tariffs'}
                                                             buttonLabel={'Manage Tariffs'}
                                                             sitesWithout={this.state.sitesWithoutTariffs}
                                                             title={this.props.title}/> : ''}
                        {index + 1 < this.state.totalValues.length ?
                            <span style={{
                                fontSize: '20px',
                                color: 'grey',
                                display: 'flex',
                                alignItems: 'center'
                            }}>+</span> : ''}

                    </div>
                </div>
            })
        }

        console.log(this.props.dataType, ' : this.props.dataType in KPI');

        if (!this.spend) totalRow = <div className={'kpi-widget-total-row'}>
            <div className={'kpi-widget-total'}>
                {this.state.total}
            </div>
            <div className={'unit-column'}>
                {this.state.change ? <div className={'percentage-changed'}>
                    <Icon icon={this.state.upDown === 'up' ? 'FaArrowUp' : 'FaArrowDown'}
                          size={16}
                          color={this.state.upDown !== desiredDirection ? 'gd-red' : 'gd-green'}/> <span
                    className={(this.state.upDown !== desiredDirection ? 'down' : 'up')}>{this.state.change}%</span>
                </div> : <div className={'kpi-widget-total-row no-cont'}/>}
                <div className={'unit'}>
                    {this.state.unit}
                </div>
            </div>
        </div>

        return (
            <div
                className={'KPI-widget ' + (this.state.ready ? 'loaded ' : '') + (this.spend ? 'spend ' : '') + (this.state.noTariff ? 'no-tariffs ' : '')}
                style={{backgroundColor: formatter('hexToRGB')(this.props.color, 0.1, true)}}>
                <div style={{display: 'none'}} id={"graph" + this.props.id}/>
                <div className={'kpi-widget-header'}>
                    <Tooltip label={this.props.dataType.category}><div className={'icon-wrapper'} style={{backgroundColor: this.props.color}}>
                        {this.props.icon ? <Icon icon={this.props.icon}
                                                 size={14}
                                                 color={'white'}/> : null}
                    </div></Tooltip>
                    <p>Total {titleOverrides[this.props.title] || this.props.title}</p>
                </div>
                {this.state.ready && !this.state.noData ? totalRow : null}
                {!this.state.noTariff ? spendRows :
                    <div className={'no-tariff-wrapper'}>
                        <div className={'row no-tariff'}>{this.state.hasTariffs ? [(
                            <span key={'link'} className={'link'}
                                  onClick={this.assignRoute}>Assign </span>), (<span key={'or'}>or</span>)] : ''}<Button
                            onClick={() => this.setState({
                                openTariff: true,
                                sites: this.props.assets && this.props.assets.length ? this.props.assets.map((a) => a.siteId) : this.props.item.siteIds
                            })}
                            label={'Create'}/> {this.props.title ? this.props.title.replace(/Spend/g, '').toLowerCase() : ''} tariff
                        </div>
                    </div>}

                {this.state.openTariff ?
                    <TariffModal onSave={(message) => this.setState({showToast: message})}
                                 onClose={() => this.setState({openTariff: false})}
                                 open={this.state.openTariff} sites={this.state.sites}
                                 utilityType={this.spendDataType}/> : null}
                <Toast onClose={() => {
                    this.setState({showToast: false, ready: false}, () => {
                        self.load();
                    })
                }} message={this.state.showToast}
                       open={!!(this.state.showToast)}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>
                {!this.state.ready ?
                    <div className={'kpi-loader ' + this.props.id}><GenericLoader/></div> : null}
                {this.state.ready && this.state.noData ?
                    this.props.isPendingData ?
                        <div key={'ne' + this.props.id} className={'not-enough'}><GenericLoader/></div> :
                        <div key={'ne' + this.props.id} className={'not-enough'}>No Data</div> : null}
                {!this.state.noTariff && spendRows && !spendRows.length ?
                    <div key={'ne' + this.props.id} className={'not-enough'}>No Data</div> : ''}
            </div>
        )
    }

}

export default KPIWidget;