import React, {Component} from "react";
import ReactDOM from 'react-dom'
import MenuItem from "./MenuItem"
import KeepComponentOnScreen from "../KeepComponentOnScreen"
import "./Menu.scss";

class LMenu extends Component {

	//TODO Make the JSON not require the use of depth in the sub-children
	//FIXME Make the submenu logic better so you don't have to close/open the menu again to select another item if you went wrong in the tree. Think the fix can be done by injecting the submenu item as a child component instead of passing it through.

	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			uid: Math.random()
		};
		this.hideMenu = this.hideMenu.bind(this);
		this.showMenu = this.showMenu.bind(this);
		this.changePos = this.changePos.bind(this);
	}

	hideMenu(e) {
		if (e) {
			e.stopPropagation();
			e.preventDefault();
		}
		if (this.props.closeParent) {
			this.props.closeParent();
		}

		this.setState({showMenu: false, fadedIn: false});
	}

	showMenu(e) {
		console.log('menu clicked')
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		let position = ReactDOM.findDOMNode(this).getBoundingClientRect();
		let amountToPush = 0;
		if (this.props.lvl) amountToPush = position.width;
		this.setState({top: position.top, left: position.left + amountToPush});
		this.setState({showMenu: true});
		if (this.props.createdByMenuItem) {
			this.props.createdByMenuItem.setState({selected: true})
		}
		if (this.props.rightClick) {
			//disable all other click behaviour off menu
		}
	}

	changePos(newPos) {
		if (this.state.showMenu) {
			this.setState({
				left: newPos.x ? newPos.x : this.state.left,
				fadedIn: true
			});
		}
	}


	render() {
		let self = this;
		let menuHeader;
		let menuItems;

		if (this.props.menuHeader) {
			menuHeader =
				<div className={'MenuHeader'}>
					<p className={'title'}>{this.props.menuHeader.title}</p>
					<p className={'subtitle'}>{this.props.menuHeader.subtitle}</p>
				</div>
		}

		if (this.props.menuItems) {
			menuItems = this.props.menuItems.map(function (item, index) {
				if (!item.type) {
					return (<MenuItem color={item.color} inMenu={self} key={"Item" + index} disabled={item.disabled}
									  onMenuItemClick={function (e) {
										  if (!item.subMenu && e) {
											  e.stopPropagation();
											  e.preventDefault();
										  }
										  if (!item.subMenu && item.onMenuItemClick) {
											  item.onMenuItemClick(e);
										  }
									  }}
									  closeParent={self.props.closeParent ? self.props.closeParent : null}
									  closeMenu={self.hideMenu} subMenu={item.subMenu} icon={item.icon}
									  closeSiblings={self.closeSiblings}
									  label={item.label}/>)
				} else  {
					if (item.type === 'divider') {
						return <p className={'menu-divider'} key={"menu-divider"}>{item.title}</p>
					}
				}
			});
		}

		let menu =
			<div className={'Menu ' + (this.state.fadedIn ? ' showing' : '') + (this.props.disabled ? ' disabled' : '')} style={{
				left: this.state.left,
				top: this.state.top,
				zIndex: this.props.lvl ? this.props.lvl + 999 : 999,
				maxHeight: (window.innerHeight - this.state.top) + 'px',
				overflow: 'auto'
			}}>
				<KeepComponentOnScreen changePos={this.changePos}>
					<div>
						{menuHeader}
						{menuItems}
					</div>
				</KeepComponentOnScreen>
			</div>;

		let menuWrapper =
			<div className={'MenuWrapper' + (this.props.disabled ? ' disabled' : '')} style={{'zIndex': this.props.lvl ? this.props.lvl + 998 : 998}}
				 onClick={self.hideMenu}/>;

		return (
			<div id={'MenuShowContainer'} className={'' + (this.props.disabled ? ' disabled' : '')} ref={this.menuRef} key={"MenuShow"}>
				<div ref={this.menuRef}
					 className={'menu-show-inner'}
					 onContextMenu={this.props.rightClick && !this.props.disabled ? self.showMenu : null}
					 onClick={!this.props.rightClick && !this.props.disabled ? self.showMenu : null}>
					{this.props.controlFromElement}
				</div>

				{this.state.showMenu
					? ReactDOM.createPortal(
						React.cloneElement(menu, { key: "menu" }), // Adding key to menu
						document.body
					)
					: null}

				{this.state.showMenu
					? ReactDOM.createPortal(
						React.cloneElement(menuWrapper, { key: "menuWrapper" }), // Adding key to menuWrapper
						document.body
					)
					: null}
			</div>
		)
	}
}

LMenu.defaultProps = {
	rightClick: false
};

export default LMenu;
