import cookie from "react-cookies";
import GridDuck from "gridduck";
import {isMobile, isTablet} from "react-device-detect";
import {Router, Redirect, Route, Switch} from "react-router-dom";
import LoggedInPage from "../routing/LoggedInPage";
import ReactDOM from "react-dom";
import React, {createContext, useState, useMemo} from "react";
import './index.scss';
import AdminConsole from "../pages/adminconsole/AdminConsole";
import RulePage from "../pages/rule/RulePage";
import EditRulePage from "../pages/edit-rule/EditRulePage";
import OrganisationPage from "../pages/organisation/MainOrganisationPage";
import CreateAssetPage from "../pages/asset/CreateAssetPage";
import AccountPage from "../pages/account/Account";
import LeftNav from "../navigation/LeftNav";
import TopNav from "../navigation/TopNav";
import Content from "../navigation/Content";
import history from "./history";
import MainAlertsPage from "../pages/alerts/MainAlertsPage";
import CustomFieldListPage from '../pages/CustomFieldListPage';
import TariffListPage from "../pages/TariffListPage";
import InvoiceListPage from "../pages/InvoiceListPage";
import ErrorBoundary from "./error-boundary";
import DataDownloadMainPage from "../pages/dataDownload/DataDownloadMainPage";
import DeveloperPage from "../pages/developer/DeveloperPage";
// import LostConnectionIndicator from "../components/LostConnectionIndicator/LostConnectionIndicator";
import NotFoundPage from "../pages/not-found/NotFoundPage";
import PublicPage from "../routing/PublicPage";
import ExampleLogin from "../pages/ExampleLogin";
import MainAuditPage from "../pages/site/audit/MainAuditPage";
import ReportsPage from "../pages/reports/MainReportsPage";
import MobileReportsPage from "../pages/reports/MobileMainReportsPage";
import MobileTopNav from "../navigation/MobileTopNav";
import DesktopLogin from "../pages/DesktopLogin";
import MobileLogin from "../pages/MobileLogin";
import MobileMainSiteGroupPage from "../pages/sitegroup/MobileMainSiteGroupPage";
import MobileMainSitePage from "../pages/site/MobileMainSitePage";
import MobileMainAssetPage from "../pages/asset/MobileMainAssetPage";
import DesktopPasswordReset from "../pages/DesktopPasswordReset";
import MobilePasswordReset from "../pages/MobilePasswordReset";
import MobileForgot from "../pages/MobileForgot";
import DesktopForgot from "../pages/DesktopForgot";
import SavingsCalculator from "../pages/savingsCalculator/savingsCalculator";
import AnomalyDetectionPage from '../pages/anomalyDetection/anomalyDetection'
import MobileConfirmInvite from "../pages/MobileConfirmInvite";
import DesktopConfirmInvite from "../pages/DesktopConfirmInvite";
import MobileBottomNav from "../navigation/MobileBottomNav";
import DemoBar from "../components/DemoBar/DemoBar";
import AutomationListPage from "../pages/AutomationListPage";
import AlertListPage from "../pages/AlertListPage";
import MainPage from "../pages/MainPage";
import InspiredLogin from "../pages/sso/InspiredLogin";
import DisconnectedDrawer from "../components/Disconnected/DisconnectedDrawer";
import LoggedInAsBar from "../LoggedInAsBar";
import Gaia from "../components/gaia/GaiaWindow";
// import * as Sentry from "@sentry/react";
//
// if (window.location.hostname === 'dashboard.gridduck.com') {
//     Sentry.init({
//         dsn: "https://76e0f1813d586884b5b228fe23fadd7c@o4505804594544640.ingest.sentry.io/4505805912211456",
//         integrations: [
//             new Sentry.BrowserTracing({
//                 // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//                 tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//             }),
//             new Sentry.Replay(),
//         ],
//         // Performance Monitoring
//         tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//         // Session Replay
//         replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//         replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//     });
// }

const MainPageRoutes = (props) => {
    console.log(props, ' : props in MainPageRoutes');
    return <Switch>
        <LoggedInPage {...props}
            path="/:itemType/:itemId/:subpage1/:subpage2/:subpage3"
            component={MainPage}/>
        <LoggedInPage {...props} path="/:itemType/:itemId/:subpage1/:subpage2"
                      component={MainPage}/>
        <LoggedInPage {...props} path="/:itemType/:itemId/:subpage1"
                      component={MainPage}/>
        <LoggedInPage {...props} path="/:itemType/:itemId"
                      component={MainPage}/>
        <LoggedInPage {...props} path="/:itemType"
                      component={MainPage}/>
    </Switch>
}
const AdminConsoleRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props}
            path="/admin-console/:subpage1/:subpage2/:subpage3"
            component={AdminConsole}/>
        <LoggedInPage {...props} path="/admin-console/:subpage1/:subpage2"
                      component={AdminConsole}/>
        <LoggedInPage {...props} path="/admin-console/:subpage1"
                      component={AdminConsole}/>
        <LoggedInPage {...props} path="/admin-console" component={AdminConsole}/>
    </Switch>
)
const AnomalyDetectionRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/anomaly-detection/:subpage1"
                      component={AnomalyDetectionPage}/>
        <LoggedInPage {...props} path="/anomaly-detection"
                      component={AnomalyDetectionPage}/>
    </Switch>
)
const SettingsRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/settings/:subpage1/:subpage2/:subpage3"
                      component={AccountPage}/>
        <LoggedInPage {...props} path="/settings/:subpage1/:subpage2"
                      component={AccountPage}/>
        <LoggedInPage {...props} path="/settings/:subpage1"
                      component={AccountPage}/>
        <LoggedInPage {...props} path="/settings" component={AccountPage}/>
    </Switch>
)
const OrganisationRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/organisation/:subpage1"
                      component={OrganisationPage}/>
        <LoggedInPage {...props} path="/organisation"
                      component={OrganisationPage}/>
    </Switch>
)
const ReportsRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/reports/:reportId/:timeStart/:pdfId"
                      component={ReportsPage}/>
        <LoggedInPage {...props} path="/reports/:reportId/:timeStart"
                      component={ReportsPage}/>
        {/*<LoggedInPage path="/reports/create"*/}
        {/*              component={CreateReportsPage}/>*/}
        <LoggedInPage {...props} path="/reports/:reportId"
                      component={ReportsPage}/>
        <LoggedInPage {...props} path="/reports" component={ReportsPage}/>
    </Switch>
)
const AuditRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/audit/:auditId/:subpage1"
                      component={MainAuditPage}/>
        <LoggedInPage {...props} path="/audit/:auditId" component={MainAuditPage}/>
    </Switch>
)
const AutomationRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/automations/:automationId"
                      component={AutomationListPage}/>
        <LoggedInPage {...props} path="/automations" component={AutomationListPage}/>
    </Switch>
)
const AlertsRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/alerts/:alertId"
                      component={AlertListPage}/>
        <LoggedInPage {...props} path="/alerts" component={AlertListPage}/>
    </Switch>
)
const DataDownloadRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/data-download/:subpage1"
                      component={DataDownloadMainPage}/>
        <LoggedInPage {...props} path="/data-download"
                      component={DataDownloadMainPage}/>
    </Switch>
)
const TariffsRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/tariffs/:tariffId"
                      component={TariffListPage}/>
        <LoggedInPage {...props} path="/tariffs" component={TariffListPage}/>
    </Switch>
)
const CustomFieldsRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/custom-fields/:customFieldTypeId"
                      component={CustomFieldListPage}/>
        <LoggedInPage {...props} path="/custom-fields"
                      component={CustomFieldListPage}/>
    </Switch>
)
const IssuesRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/issues/:subpage1"
                      component={MainAlertsPage}/>
        <LoggedInPage {...props} path="/issues" component={MainAlertsPage}/>
    </Switch>
)
const RuleRoutes = (props) => (
    <Switch>
        <LoggedInPage {...props} path="/rule/:ruleId/edit"
                      component={EditRulePage}/>
        <LoggedInPage {...props} path="/rule/:ruleId" component={RulePage}/>
    </Switch>
)

export const DrawerContext = createContext(null);

const App = function (props) {
    console.log('Build App');
    const isAppMobile = useMemo(() => isMobile && !isTablet, []);
    const [drawerState, setDrawerState] = useState({
        openDisconnectedDrawer: false,
        openGaiaDrawer: false
    });

    if (isAppMobile) {
        document.getElementById('viewport').setAttribute("content", 'width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0');
    }

    //Remove console logs
    if (window.location.hostname === 'dashboard.gridduck.com') {
        console.log = function () {
        };
    }

    //Redirect for trial kit
    // if (window.location.pathname === '/trial-kit') {
    //     return window.location.replace('https://legacy.dashboard.gridduck.com/trial-kit');
    // }

    let accessToken = cookie.load('accessToken');
    let redirect;
    const loggedOut = function () {
        history.completeUrl = history.location.pathname;
        GridDuck.deleteAccessToken();
        cookie.remove('accessToken', {path: '/'});
        history.push("/");
    }
    GridDuck.onLoggedOut(loggedOut);

    if (accessToken && accessToken.user) {
        if (window.location.pathname === '/') {
            redirect = (<Redirect to='/siteGroup/all'/>);
        }
        GridDuck.setAccessToken(accessToken)
            .catch(function (err) {
                loggedOut();
            });
    } else {
        if (history.location.pathname.indexOf('config-invite') === -1) {
            history.completeUrl = history.location.pathname;
        }
    }

    const updateFunctions = {updateDisconnectedDrawer: setDrawerState};

    if (isAppMobile) {
        return <ErrorBoundary>
            <Router history={history}>
                <Switch>
                    <PublicPage exact path="/" component={MobileLogin}/>
                    <PublicPage exact path="/savings-calculator-embed" component={SavingsCalculator}/>
                    <PublicPage exact path="/confirm-invite/:inviteId" component={MobileConfirmInvite}/>
                    <PublicPage path="/forgot" component={MobileForgot}/>
                    <PublicPage path="/password-reset/:token" component={MobilePasswordReset}/>
                    <PublicPage path="/example/wQ1CUdEkqpWtPyhHIQbspwcUR5c9KIF2" component={ExampleLogin}/>
                    <Route>
                        <div id="mobile_decorated_page">
                            {/*<LostConnectionIndicator/>*/}
                            <div id="right_content_wrapper">
                                <MobileTopNav/>
                                <Content>
                                    <Switch>
                                        {/*<LoggedInPage path="/portfolio" component={MobileOverviewPage}/>*/}
                                        {/*<LoggedInPage path="/siteGroup" component={() => (*/}
                                        {/*    <Switch>*/}
                                        {/*        <LoggedInPage path="/siteGroup/:siteGroupId/:subpage1"*/}
                                        {/*                      component={MobileMainSiteGroupPage}/>*/}
                                        {/*        <LoggedInPage path="/siteGroup/:siteGroupId"*/}
                                        {/*                      component={MobileMainSiteGroupPage}/>*/}
                                        {/*    </Switch>*/}
                                        {/*)}/>*/}
                                        {/*<LoggedInPage path="/site" component={() => (*/}
                                        {/*    <Switch>*/}
                                        {/*        <LoggedInPage path="/site/:siteId/:subpage1/:subpage2"*/}
                                        {/*                      component={MobileMainSitePage}/>*/}
                                        {/*        <LoggedInPage path="/site/:siteId/:subpage1"*/}
                                        {/*                      component={MobileMainSitePage}/>*/}
                                        {/*        <LoggedInPage path="/site/:siteId" component={MobileMainSitePage}/>*/}
                                        {/*    </Switch>*/}
                                        {/*)}/>*/}
                                        <LoggedInPage path="/reports" component={() => (
                                            <Switch>
                                                <LoggedInPage path="/reports/:reportId/:timeStart/:pdfId"
                                                              component={MobileReportsPage}/>
                                                <LoggedInPage path="/reports/:reportId/:timeStart"
                                                              component={MobileReportsPage}/>
                                                <LoggedInPage path="/reports/:reportId"
                                                              component={MobileReportsPage}/>
                                                <LoggedInPage path="/reports" component={MobileReportsPage}/>
                                            </Switch>
                                        )}/>
                                        {/*<LoggedInPage path="/device" component={() => (*/}
                                        {/*    <Switch>*/}
                                        {/*        <LoggedInPage path="/device/:assetId/:subpage1/:subpage2"*/}
                                        {/*                      component={MobileMainAssetPage}/>*/}
                                        {/*        <LoggedInPage path="/device/:assetId/:subpage1"*/}
                                        {/*                      component={MobileMainAssetPage}/>*/}
                                        {/*        <LoggedInPage path="/device/:assetId" component={MobileMainAssetPage}/>*/}
                                        {/*    </Switch>*/}
                                        {/*)}/>*/}
                                        <LoggedInPage updateFunctions={updateFunctions} path="/" component={MainPageRoutes}/>

                                        <Route component={NotFoundPage}/>
                                    </Switch>
                                </Content>
                                <MobileBottomNav/>
                                <DemoBar/>
                            </div>
                        </div>
                    </Route>
                </Switch>
                <Route path="/404" exact={true} component={NotFoundPage}/>
                {redirect}
            </Router>
        </ErrorBoundary>
    } else {
        return <ErrorBoundary>
            <Router history={history}>
                <Switch>
                    <PublicPage exact path="/" component={DesktopLogin}/>
                    <PublicPage exact path="/savings-calculator-embed" component={SavingsCalculator}/>
                    <PublicPage exact path="/confirm-invite/:inviteId" component={DesktopConfirmInvite}/>
                    <PublicPage path="/forgot" component={DesktopForgot}/>
                    {/*TODO Add back in for E-commerce*/}
                    {/*<PublicPage path="/create-organisation" component={DesktopCreateOrganisation}/>*/}
                    <PublicPage path="/password-reset/:token" component={DesktopPasswordReset}/>
                    <PublicPage path="/example/wQ1CUdEkqpWtPyhHIQbspwcUR5c9KIF2" component={ExampleLogin}/>
                    <PublicPage path="/inspired-login" component={InspiredLogin}/>
                    <Route>
                        <DemoBar/>
                        {accessToken?.partnerOverride ? <LoggedInAsBar/> : null}
                        <div id="decorated_page" className={accessToken?.partnerOverride ? ' partner-override' : ''}>
                            {/*<LostConnectionIndicator/>*/}
                            <DrawerContext.Provider value={{setDrawerState, drawerState}}>
                                <LeftNav key="LeftNav" updateFunctions={updateFunctions}/>
                                {drawerState.openDisconnectedDrawer ?
                                    <DisconnectedDrawer key="DisconnectedDrawer" open={drawerState.openDisconnectedDrawer}
                                                        filterId={drawerState.filterId}
                                                        filterType={drawerState.filterType}
                                                        filterItem={drawerState.filterItem}
                                                        openOnTab={0}
                                                        updateFunctions={updateFunctions}
                                                        onClose={() => setDrawerState({openDisconnectedDrawer: false})}/> : null}
                                {drawerState.openGaiaDrawer ?
                                    <Gaia key="Gaia" open={drawerState.openGaiaDrawer}
                                                        updateFunctions={updateFunctions}
                                                        onClose={() => setDrawerState({openGaiaDrawer: false})}/> : null}
                            </DrawerContext.Provider>
                            <div id="right_content_wrapper">
                                <TopNav updateFunctions={updateFunctions}/>
                                <Content>
                                    <Switch>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/admin-console" component={AdminConsoleRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/anomaly-detection" component={AnomalyDetectionRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/settings" component={SettingsRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/organisation" component={OrganisationRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/reports" component={ReportsRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/audit" component={AuditRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/automations" component={AutomationRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/alerts" component={AlertsRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/data-download" component={DataDownloadRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/developer" component={DeveloperPage}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/tariffs" component={TariffsRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/custom-fields" component={CustomFieldsRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/invoices" component={InvoiceListPage}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/issues" component={IssuesRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/devices/create" component={CreateAssetPage}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/rule" component={RuleRoutes}/>
                                        <LoggedInPage updateFunctions={updateFunctions} path="/" component={MainPageRoutes}/>
                                        <Route component={NotFoundPage}/>
                                    </Switch>
                                </Content>
                            </div>
                        </div>

                    </Route>
                </Switch>
                <Route path="/404" exact={true} component={NotFoundPage}/>
                {redirect}
            </Router>
        </ErrorBoundary>
    }

}

ReactDOM.render(<App/>, document.getElementById('root'));