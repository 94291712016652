import React, {Component} from "react";
import "./AddInvoiceModal.scss";
import '../../styles/_layout.scss';
import {Button, CardBody, GdCalendar, Input, DateTimeRangePicker, Tabs, EditableList, StandardListCell} from "gd-react";
import GridDuck from "gridduck";
import GdModal from '../../components/GdModal/GdModal'
import moment from "moment-timezone";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import BreadcrumbService from "../../meta/breadcrumb-service";
import LocationForm from "../../components/LocationForm";
import OptionalField from "./OptionalField";
import InvoicePreview from "./InvoicePreview";

class AddInvoiceModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.sitesChanged = this.sitesChanged.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.saveDetails = this.saveDetails.bind(this);
        this.errors = {};
        this.changed = false;

        let thirtyDaysInFuture = moment().add(30, 'days').format('YYYY-MM-DD');

        console.log(thirtyDaysInFuture, ' : thirtyDaysInFuture');

        this.state = {
            showing: {},
            id: '',
            vatId: '',
            customerName: '',
            customerEmail: '',
            memo: '',
            logo: false,
            issuingOrgName: '',
            issuingOrgPhoneNumber: '',
            issuingOrgEmail: '',
            dueDate: thirtyDaysInFuture,
            vat: 20,
            loaded: false,
            newSitesList: [],
            dateRange: {}
        }

        this.modalClosed = false;
        this.tabs = [[
            {
                id: 1,
                title: 'Details',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
            },
            {
                id: 2,
                title: 'Items',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 2)
            }
        ]]
    }

    async saveDetails() {

        let errors = this.checkErrors(false, true);
        console.log(errors, ' : errors');
        if (errors) errors = Object.keys(errors).length;
        if (errors) return Promise.reject();

        let startDate = moment(this.state.dateRange.start).format('YYYY-MM-DD'),
            endDate = moment(this.state.dateRange.end).format('YYYY-MM-DD'),
            assetIds = [],
            invoiceNo = this.state.id,
            dueDate = this.state.dueDate,
            issuingOrgName = this.state.issuingOrgName,
            issuingOrgPhoneNumber = this.state.issuingOrgPhoneNumber,
            issuingOrgEmail = this.state.issuingOrgEmail,
            vat = this.state.vat,
            vatId = this.state.vatId && this.state.showing['vatId'] ? this.state.vatId : null,
            customerName = this.state.customerName,
            customerEmail = this.state.showing['customerEmail'] ? this.state.customerEmail : null,
            memo = this.state.showing['memo'] ? this.state.memo : null,
            includeBranding = this.state.showing['logo'] && this.state.logoImage ? true : false;

        if (memo) {
            memo.replace('\n', '"\n"')
            console.log(memo, ' : memo');
        }

        await Promise.all(this.state.newSitesList.map((s) => {
            return GridDuck.getAssets({
                filters: [{
                    field: 'siteId',
                    value: s.id
                }], getAll: true
            }).then((a_ids) => {
                assetIds = assetIds.concat(a_ids.list.map((a) => a.id));
            });
        }));

        let paramsObject = {
            startDate: startDate,
            endDate: endDate,
            assetIds: assetIds,
            invoiceNo: invoiceNo,
            dueDate: dueDate,
            issuingOrgName: issuingOrgName,
            issuingOrgPhoneNumber: issuingOrgPhoneNumber,
            issuingOrgEmail: issuingOrgEmail,
            vat: vat,
            customerName: customerName,
            customerEmail: customerEmail,
            memo: memo,
            includeBranding: includeBranding,
            vatId: vatId
        }

        console.log(paramsObject, ' : params object');

        await GridDuck.createInvoiceRequest(paramsObject);
        this.closeModal();
        history.push('/invoices');
        return Promise.resolve();
    }

    sitesChanged(newSitesList) {
        console.log(newSitesList, ' new sites list on editable save')
        this.setState({newSitesList: newSitesList, formUpdated: true, sitesUpdated: true})
    }

    getAvailableList(params) {
        return GridDuck.getSites(params)
    }

    getActiveList() {
        console.log(this.state.newSitesList, ' : this.state.newSitesList');
        return Promise.resolve({list: this.state.newSitesList, total: this.state.newSitesList.length});
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    componentDidMount() {
        let self = this;
        //Get count of number of invoices
        GridDuck.getAccount({id: GridDuck.userId}).then(function (user) {
            Promise.all([GridDuck.getOrganisation({id: user.organisationId}), GridDuck.getInvoiceRequests({
                items: 1,
                offset: 0
            })]).then((res) => {
                console.log(res, ' : res');
                console.log(user, ' : res.list.length');
                let number = ((res[1].list.length + 1) + '').padStart(4, '0');
                let memo = res[1].list.length ? res[1].list[0].memo : '';

                self.setState({
                    id: 'GD-INV-' + number,
                    vatId: res[0].vatId,
                    loaded: true,
                    memo: memo,
                    issuingOrgName: user.organisationName,
                    issuingOrgPhoneNumber: res[0].contactPhone,
                    issuingOrgEmail: user.billingEmail,
                    logoImage: user.logoImageThumbnail
                });
            });
        });
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        this.state[val.target.name] = val.target.value;
        this.setState(this.state);
    }

    moveToTab() {
        let errors = this.checkErrors(true);
        console.log(errors, ' : errors');
        if (errors) errors = Object.keys(errors).filter((e) => e !== 'dateRange' && e !== 'siteIds').length;
        if (errors) return Promise.reject();
        this.onTabClick(null, {id: 2});
        return Promise.resolve();
    }

    checkErrors(saving, final) {
        let errors = {};
        if (!this.state.id.length) errors['id'] = true;
        if (!this.state.customerName.length) errors['customerName'] = true;
        if (!this.state.dueDate.length) errors['dueDate'] = true;
        if (!this.state.dateRange || !this.state.dateRange.start || !this.state.dateRange.end) errors['dateRange'] = true;
        if (!this.state.newSitesList || !this.state.newSitesList.length) errors['siteIds'] = true;
        this.errors = errors;
        if (saving) this.setState({triedToSave: true});
        if (final) this.setState({triedToSaveTwo: true});
        return errors;
    }

    addRemoveField(state, field) {
        this.state.showing[field] = state;
        this.setState(this.state);
    }

    render() {
        let footer;
        let invoiceErrors = this.checkErrors();
        if (invoiceErrors) invoiceErrors = Object.keys(invoiceErrors).length;
        this.tabs[0][1].disabled = invoiceErrors;
        if (this.state.loaded && this.tabs[0][0] && this.tabs[0][0].selected) footer =
            <Button additionalclasses={'sm'}
                    label={'Next'}
                    color={'gd-brand'}
                    onClick={this.moveToTab}/>

        if (this.state.loaded && this.tabs[0][1] && this.tabs[0][1].selected) footer =
            <Button progressRes additionalclasses={'sm'}
                    label={'Create Invoice'} color={'gd-green'}
                    onClick={this.saveDetails}/>

        this.checkErrors();
        return (
            <GdModal
                open={this.props.open}
                contentLabel={'Create Invoice'}
                title={'Create Invoice'}
                footer={footer}
                sideContent={<InvoicePreview params={this.state}/>}
                onClose={this.closeModal}>
                {this.tabs ? <Tabs tabData={this.tabs}/> : null}
                {this.state.loaded ? <CardBody>
                    {this.tabs[0][0] && this.tabs[0][0].selected ?
                        <div className={'create-invoice'}>
                            <div className={'row'}>
                                <Input required error={this.errors['id'] && this.state.triedToSave} top='true'
                                       onChange={this.onFormChange}
                                       name={'id'} value={this.state.id} label={'ID'}/>
                            </div>
                            <div className={'row'}>
                                <Input name={'dueDate'} error={this.errors['dueDate'] && this.state.triedToSave}
                                       onChange={this.onFormChange} value={this.state.dueDate} type={'date'}
                                       inputProps={{min: new Date('YYYY-MM-DD')}} required
                                       label={'Due Date ' + (this.state.dueDate ? '( ' + moment(this.state.dueDate).fromNow() + ' )' : '')}/>
                            </div>
                            <div className={'row'}>
                                <Input required error={this.errors['customerName'] && this.state.triedToSave}
                                       onChange={this.onFormChange}
                                       name={'customerName'} value={this.state.customerName} label={'Recipient Name'}/>
                            </div>

                            <div className={'row'}>
                                <OptionalField label={'Recipient Email'} showing={this.state.showing['customerEmail']}
                                               onAddRemove={(state) => this.addRemoveField(state, 'customerEmail')}>
                                    <Input error={this.errors['customerEmail'] && this.state.triedToSave}
                                           onChange={this.onFormChange}
                                           name={'customerEmail'} value={this.state.customerEmail}
                                           label={'Recipient Email'}/>
                                </OptionalField>
                            </div>
                            <div className={'row'}>
                                <OptionalField label={'VAT %'} showing={this.state.showing['vat']}
                                               onAddRemove={(state) => this.addRemoveField(state, 'vat')}>
                                    <Input inputProps={{step: 1, min: 0, max: 100}}
                                           error={this.errors['vat'] && this.state.triedToSave}
                                           onChange={this.onFormChange}
                                           type={'number'}
                                           name={'vat'} value={this.state.vat} label={'VAT %'}/>
                                </OptionalField>
                            </div>
                            <div className={'row'}>
                                <OptionalField label={'VAT ID'} showing={this.state.showing['vatId']}
                                               onAddRemove={(state) => this.addRemoveField(state, 'vatId')}>
                                    <Input error={this.errors['vatId'] && this.state.triedToSave}
                                           onChange={this.onFormChange}
                                           name={'vatId'} value={this.state.vatId} label={'VAT ID'}/>
                                </OptionalField>
                            </div>
                            <div className={'row'}>
                                <OptionalField label={'Memo'} showing={this.state.showing['memo']}
                                               onAddRemove={(state) => this.addRemoveField(state, 'memo')}>
                                    <Input error={this.errors['memo'] && this.state.triedToSave}
                                           onChange={this.onFormChange}
                                           multiline
                                           inputProps={{minRows: 3, maxRows: 4}}
                                           name={'memo'} value={this.state.memo} label={'Memo'}/>
                                </OptionalField>
                            </div>
                            <div className={'column'}>
                                {this.state.logoImage ?
                                    <OptionalField label={'Branding'} showing={this.state.showing['logo']}
                                                   onAddRemove={(state) => this.addRemoveField(state, 'logo')}>
                                        <div className={"column"} style={{flex: 0}}>
                                            <label style={{
                                                margin: '15px',
                                                marginBottom: 0
                                            }}>Branding</label>
                                            <img className={'logo-example'} src={this.state.logoImage}/>
                                        </div>
                                    </OptionalField> : null}
                                {this.state.showing['logo'] ?
                                    <p style={{fontSize: '12px', padding: '0 15px'}}>Go to <span onClick={() => {
                                        this.closeModal();
                                        history.push('/settings/organisation/general');
                                    }} className={'link'}>Organisation Settings</span> to update your company logo
                                    </p> : null}
                                {!this.state.logoImage ?
                                    <p style={{fontSize: '12px', padding: '0 15px', marginTop: '15px'}}>Go to <span
                                        onClick={() => {
                                            this.closeModal();
                                            history.push('/settings/organisation/general');
                                        }} className={'link'}>Organisation Settings</span> to add your company logo
                                    </p> : null}
                            </div>
                        </div> : null}
                    {this.tabs[0][1] && this.tabs[0][1].selected ?
                        <div
                            className={'create-invoice ' + (this.state.triedToSaveTwo && this.errors['dateRange'] ? 'date-error' : '') + (this.state.triedToSaveTwo && this.errors['siteIds'] ? ' list-error' : '')}>
                            <DateTimeRangePicker placement={'bottomEnd'} label={'Invoice Date Range'} top={'true'}
                                                 value={this.state.dateRange && this.state.dateRange.start && this.state.dateRange.end ? [this.state.dateRange.start, this.state.dateRange.end] : null}
                                                 onChange={(val) => {
                                                     this.setState({dateRange: val})
                                                 }} disabled={!this.props.loaded}/>
                            {this.state.triedToSaveTwo && this.errors['dateRange'] ?
                                <p className={'error-text'}>Please enter a billing date range</p> : null}

                            <p className={'fake-label'}>Invoice Items</p>
                            {this.state.loaded ? <div className={'row'} style={{maxHeight: '550px', marginTop: '10px'}}>
                                <EditableList
                                    isEditing={true}
                                    columns={[
                                        {
                                            component: StandardListCell,
                                            bold: true,
                                            title: 'Name',
                                            field: 'name',
                                            sortable: true,
                                            defaultSort: 'asc'
                                        },
                                        {
                                            component: StandardListCell,
                                            title: 'Access level',
                                            field: '_permission',
                                            maxWidth: 'none'
                                        },
                                    ]}
                                    key={1}
                                    onChange={this.sitesChanged}
                                    noCancel={true}
                                    noSave
                                    getActiveList={this.getActiveList}
                                    getAvailableList={this.getAvailableList}
                                />
                            </div> : <GenericLoader/>}
                            {this.state.triedToSaveTwo && this.errors['siteIds'] ?
                                <p className={'error-text'}>Please at least one item</p> : null}
                        </div> : ''}
                </CardBody> : ''}
                {!this.state.loaded ? <CardBody>
                    <GenericLoader/>
                </CardBody> : ''}
            </GdModal>
        )
    }
}

export default AddInvoiceModal;