import React, {Component} from "react";
import "./AddAutomationModal.scss";
import '../../styles/_layout.scss';
import '../../pages/create-rule/CreateRule.scss'
import {Button, CardBody, StateOptions} from "gd-react";
import GdModal from "../../components/GdModal/GdModal";
import CreateRulePage from "../../pages/create-rule/CreateRulePage";
import CreateRulePageSelectTemplate from "../../pages/create-rule/CreateRulePageSelectTemplate";
import EditRulePageTimeswitch from "../../pages/edit-rule/EditRulePageTimeswitch";
import EditRulePageAlert from "../../pages/edit-rule/EditRulePageAlert";
import EditRulePageThermostat from "../../pages/edit-rule/EditRulePageThermostat";
import EditRulePageAdvanced from "../../pages/edit-rule/EditRulePageAdvanced";
import RulePage from "../../pages/rule/RulePage";
import DeleteModal from "../Delete/DeleteModal";
import history from '../../meta/history';
import {Grid} from "@material-ui/core";
import GridDuck from "gridduck";

class AddAutomationModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.updateRule = this.updateRule.bind(this);
        let template, rule = this.props.item, templatePretty;
        if (this.props.item) {
            template = this.props.item.template;
            if (!template) template = 'advanced';
        }
        if (this.props.type === 'alert' && !this.props.item) {
            template = this.props.type;
            rule = this.updateRuleInProgress({template: template})
            templatePretty = 'Alert';
        }

        this.state = {
            template: template,
            rule: rule,
            editing: this.props.item && this.props.editing,
            templatePretty: templatePretty
        }
        this.modalClosed = false;
        if (this.props.item) {
            this.props.item.on('updated', this.updateRule);
        }
    }

    updateRule() {
        this.setState({rule: this.props.item || this.newRule});
    }

    async trackNewRule(newRuleId) {
        this.newRule = await GridDuck.getRule({id: newRuleId});
        this.newRule.on('updated', this.updateRule);
    }

    componentWillUnmount() {
        if (this.props.item) {
            this.props.item.off('updated', this.updateRule);
        }
        if (this.newRule) {
            this.newRule.off('updated', this.updateRule);
        }
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    updateRuleInProgress(updateObj) {
        if (updateObj.template !== undefined) {
            let alerts = [];
            let triggers = [];
            if (updateObj.template === 'timeswitch') {
                triggers = [{
                    details: {
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false,
                        state: StateOptions.map(s => s.options).flat()[1].state
                    }
                }];
                alerts = [{
                    type: 'ON',
                    assets: []
                }]
            }
            if (updateObj.template === 'alert') {
                triggers = [{
                    assets: [],
                    dataType: 'CONSUMPTION',
                    thresholdType: 'above',
                    instantaneous: true,
                    requireAll: true,
                    details: {
                        monday: false,
                        tuesday: false,
                        wednesday: false,
                        thursday: false,
                        friday: false,
                        saturday: false,
                        sunday: false,
                        state: -1
                    }
                }];
                alerts = [{
                    type: 'EMAIL'
                }]
            }
            if (updateObj.template === 'thermostat') {
                triggers = [{
                    assets: [],
                    dataType: 'TEMPERATURE',
                    thresholdType: 'below',
                    instantaneous: true,
                    requireAll: true,
                    isSymmetric: true
                }];
                alerts = [{
                    type: 'STATE_CHANGE',
                    assets: [],
                    state: 'ON',
                    defaultState: 'OFF'
                }]
            }
            let rule = {
                template: updateObj.template,
                alerts: alerts,
                triggers: triggers,
                creating: true
            };
            if (updateObj.template === 'alert') {
                rule.inactiveState = StateOptions.map(s => s.options).flat()[0].state
            }
            if (updateObj.template === 'thermostat') {
                rule.persistent = true;
            }
            if (updateObj.template === 'timeswitch') {
                rule.inactiveState = StateOptions.map(s => s.options).flat()[0].state
            }
            console.log("new rule", rule);
            return rule;
        }
    }

    render() {
        let isLegacy = !!(this.props.item && this.props.item.triggers && this.props.item.triggers.length)
        let Component, footer;
        if (this.state.template === 'timeswitch') {
            Component = EditRulePageTimeswitch;
        } else if (this.state.template === 'alert') {
            Component = EditRulePageAlert;
        } else if (this.state.template === 'thermostat') {
            Component = EditRulePageThermostat;
        } else if (this.state.template === 'advanced') {
            Component = EditRulePageAdvanced;
        }

        return (
            <GdModal
                title={this.state.rule && this.state.rule.name ? this.state.rule.name : !this.state.template ? "Create Automation" : ('Create ' + this.state.templatePretty)}
                open={this.props.open}
                wide={!(this.state.template === 'advanced' && (this.state.rule.creating || this.state.editing))}
                almostFullScreen={this.state.template === 'advanced' && (this.state.rule.creating || this.state.editing)}
                contentLabel={'Create New Automation'}
                footer={this.state.rule && this.state.rule.name && !this.state.editing ?
                    <div className={'row reverse'} style={{justifyContent: 'space-between'}}>
                        <div style={{display: 'flex'}}><Button label={"Edit"}
                                                               color={'gd-grey'}
                                                               disabled={isLegacy}
                                                               onClick={() => this.setState({editing: true})}/>
                        </div>
                        <div style={{marginRight: '10px', display: 'flex'}}><Button
                            onClick={() => this.setState({showDeleteModal: true})} label={"Delete"} color={'gd-red'}/>
                        </div>
                    </div> : !!(this.state.template)}
                onClose={this.closeModal}>
                <CardBody additionalclasses={"rule"}>
                    {this.state.rule && this.state.rule.name && !this.state.editing ?
                        <RulePage rule={this.state.rule}/> : null}
                    {!this.state.template ? <CreateRulePageSelectTemplate
                        onTemplateChange={(template, templatePretty) => {
                            this.setState({
                                template: template,
                                rule: this.updateRuleInProgress({template: template}),
                                templatePretty: templatePretty
                            });
                        }}/> : null}
                    {(this.state.rule && this.state.rule.creating) || this.state.editing ?
                        <Component
                            onSave={(newRule) => {
                                console.log("ONSAVE!!!!");
                                console.log(newRule, ' : new rule');
                                this.setState({editing: false}, () => {
                                    if (this.props.onCreateRule) {
                                        this.props.onCreateRule(newRule);
                                    }
                                    if (newRule) {
                                        this.setState({rule: newRule});
                                        history.push('automations/' + newRule.id);
                                    } else {
                                        if (this.props.item) {
                                            this.updateRule();
                                        } else {
                                            return this.trackNewRule(this.state.rule.id);
                                        }
                                    }
                                });
                                //     Load on new rule
                            }}

                            restartTemplate={() => {
                                this.setState({
                                    template: null,
                                    rule: null,
                                    templatePretty: null
                                })
                            }}
                            rule={this.state.rule}/> : null}
                </CardBody>
                {this.state.showDeleteModal && this.state.rule ? <DeleteModal
                    itemType={'automation'}
                    item={this.state.rule}
                    open={this.state.showDeleteModal}
                    deleteRes={this.closeModal}
                    onClose={() => {
                        this.setState({showDeleteModal: false});
                    }}/> : null}
            </GdModal>
        )
    }
}

export default AddAutomationModal;
