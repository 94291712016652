import React from 'react';
import './PortfolioList.scss';
import GridDuck from "gridduck";
import history from "../../meta/history";
import getFormat from "../../services/formatter";
import {Input, Button} from "gd-react";
import PortfolioListItems from "./PortfolioListItems";
import List from '@material-ui/core/List';
import PortfolioListItem from "./PortfolioListItem";
import AddGroupModal from "../../modals/AddGroup/AddGroupModal";

class PortfolioList extends React.Component {

    constructor(props) {
        super(props);
        let self = this;

        this.state = {
            updateRef: 0,
            loadedAllSites: true,
            openAddOverviewGroupModal: false
        }
        this.itemListUpdated = this.itemListUpdated.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadData();
        this.allGroupRef = React.createRef();
    }

    async loadData(params) {
        console.log('loading data portfolio list')
        let noSites;
        let self = this;
        let ag = this.state.allGroup;
        if (!this.state.allGroup) {
            let _ag = await GridDuck.getSiteGroups({items: 1});
            ag = _ag?.list[0];
            console.log(_ag, ' : ag');
            this.setState({allGroup: ag, sgTotal: _ag.total});
        }
        if (params?.refreshAllType && self.allGroupRef?.current) {
            //     RELOAD ALL GROUP CHILDREN
            console.log('Reload all site group');
            if (this.state.expanded) {
                self.allGroupRef.current.getItems();
            }
        }
        let promises = [
            GridDuck.getAccount({id: GridDuck.userId})];
        this.setState({loaded: false, itemList: null});
        if (this.props.groupType?.value === 'site_group') {
            promises.push(GridDuck.getSiteGroups({
                getAll: true,
                offset: 1,
                sortBy: {
                    field: 'name',
                    type: 'asc'
                }
            }));
        } else if (this.props.groupType?.value === 'device_group') {
            promises.push(GridDuck.getGroups({
                getAll: true,
                sortBy: {
                    field: 'name',
                    type: 'asc'
                }
            }));
        } else if (this.props.groupType?.value === 'client') {
            promises.push(GridDuck.getOrgPartners({
                getAll: true,
                sortBy: {
                    field: 'name',
                    type: 'asc'
                },
                filters: [
                    {field: 'type', value: 'customer'},
                    {field: 'owner', value: true}
                ]
            }));
        }

        return Promise.all(promises).then(function (itemList) {
            self.account = itemList[0];
            let items = itemList[1];
            console.log(items, ' : site groups');
            console.log(self.props.groupType?.value, ' : type');
            console.log("Got account", self.account);
            self.account.on('updated', function () {
                self.setState({updateRef: ++self.state.updateRef});
            });

            let noSites;
            if (self.props.groupType?.value === 'site_group') {
                noSites = !self.state.allGroup?.siteIds || !self.state.allGroup.siteIds.length;
                // if (allSiteGroup.siteIds.length === 1) {
                // history.push('/site/' + allSiteGroup.siteIds[0]);
                // }
            }

            console.log(items, ' : items');
            self.setState({
                itemList: items,
                loaded: true,
                noSites: noSites,
                account: self.account,
                orgPermission: self.account.orgPermission
            });

            self.props.onLoaded(noSites);

            //FIXME: idk why...
            if (items) {
                setTimeout(function () {
                    items.on('updated', self.itemListUpdated);
                    items.list.forEach(function (subscribable) {
                        if (subscribable && subscribable.on) {
                            //FIXME: disabled this subscriber because on dashboard disconnect/reconnect it will hit the API for every site group
                            subscribable.on('updated', self.itemListUpdated);
                        }
                    });
                }, 100);
            }
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.groupType?.value !== this.props.groupType?.value) {
            let refreshAllType = prevProps.groupType?.childrenLabel !== this.props.groupType?.childrenLabel;
            this.loadData({refreshAllType: refreshAllType});
        }
    }

    itemListUpdated() {
        if (this.updateTimeout) {
            clearTimeout(this.updateTimeout);
            this.updateTimeout = null;
        }
        let ts = new Date().getTime();
        if (!this.lastUpdated || ts - this.lastUpdated > 30000) {
            this.lastUpdated = new Date().getTime();
            this.setState({updateRef: this.state.updateRef + 1});
        } else if (!this.updateTimeout) {
            let delay = 30000 - (ts - this.lastUpdated);
            this.updateTimeout = setTimeout(this.itemListUpdated, delay);
        }
    }

    goToPage(page) {
        console.log(history.location, ' : history.location')
        history.push("/" + page);
    }

    render() {
        let self = this;
        let allGroup = this.state.allGroup;
        console.log(this.state.itemList, ' : this.state.itemList');
        // if (self.account) {
        return [(<List
            key={'pl-i'}
            className={'portfolio-list ' + (this.state.loadedAllSites ? 'loaded' : '')}
            sx={{
                width: '100%',
                position: 'relative',
                overflow: 'auto'
            }}>

            {this.state.allGroup && <PortfolioListItems
                expanded={this.props.groupType?.value === 'site_group' && !this.state.noSites && this.state.sgTotal <= 1}
                ref={this.allGroupRef}
                onExpandRetract={(expanded) => this.setState({expanded: expanded})}
                updateDisconnectedDrawer={this.props.updateDisconnectedDrawer}
                searchText={this.props.searchText}
                account={this.account}
                groupType={this.props.groupType}
                type={this.props.groupType?.value}
                updateRef={this.state.updateRef}
                key={allGroup.id + 'sg'} open={this.props.open}
                icon={this.props.groupType?.icon?.name}
                noSites={this.state.noSites}
                activeIssues={allGroup.issueIds && allGroup.issueIds.length ? allGroup.issueIds.filter(i => i && self.account ? (self.account.ignoredAssets.indexOf(i.id) === -1 && self.account.ignoredSites.indexOf(i.id) === -1) : false).length : 0}
                siteGroupUrl={'siteGroup/' + allGroup.id} sgl={allGroup}/>}

            {this.state.loaded && this.state.itemList ? this.state.itemList.list.filter(item => item.id.indexOf('all') === -1).sort((a, b) => a.name === 'All' || b.name === 'All' ? b.name === 'All' : a.name?.localeCompare(b.name, 'en', {numeric: true})).map((sgl, i) => {
                let siteGroupUrl = this.props.groupType?.urlLabel + '/' + sgl.id;
                let activeIssues = this.props.groupType?.value === 'site_group' && sgl.issueIds && sgl.issueIds.length ? sgl.issueIds.filter(i => i && self.account ? (self.account.ignoredAssets.indexOf(i.id) === -1 && self.account.ignoredSites.indexOf(i.id) === -1) : false).length : 0;

                return <PortfolioListItems
                    updateDisconnectedDrawer={this.props.updateDisconnectedDrawer}
                    searchText={this.props.searchText}
                    account={this.account}
                    groupType={this.props.groupType}
                    type={this.props.groupType?.value}
                    updateRef={this.state.updateRef}
                    key={sgl.id + i + 'sg'} open={this.props.open}
                    icon={this.props.groupType?.icon?.name}
                    noSites={this.state.noSites}
                    activeIssues={activeIssues}
                    siteGroupUrl={siteGroupUrl} sgl={sgl}/>
            }) : null
            }
            {!this.state.loaded ?
                <PortfolioListItem key={'pfli'} open={this.props.open} indent={1}
                                   loader={this.props.groupType?.label}/> : null}
        </List>), (this.props.open && this.state.loaded && (!this.state.itemList || !this.state.itemList.list || !this.state.itemList.list.length) && this.props.groupType?.noItems) ?
            <div key={'cnw'} className={'create-new-wrapper'}>
                <p>{this.props.groupType?.noItems.label}</p>
                {this.props.groupType?.noItems.button}
            </div> : null, this.state.openAddOverviewGroupModal ?
            <AddGroupModal key={'agm'} open={this.state.openAddOverviewGroupModal}
                           onClose={() => {
                               this.setState({openAddOverviewGroupModal: false});
                           }}/> : '']
    }
}

export default PortfolioList;
