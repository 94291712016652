import React from "react";
import "./InteractiveTooltip.scss";

class TariffBreakdown extends React.Component {

    formatCurrency(currency) {
        console.log(currency, ' : currency');
        return new Intl.NumberFormat('en-UK', {
            style: 'currency',
            currency: currency,
        });
    }

    render() {
        let v = this.props.value;
        let formatter = this.formatCurrency(v.currency);
        let dividedBy = this.props.dividedBy || 1;
        return (
            <React.Fragment>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '14px'
                }}
                     key={'u'}>Usage: <span style={{
                    marginLeft: '20px',
                    fontWeight: 700
                }}>{v.value || v.value === 0 ? formatter.format(((v.value) / 100) / dividedBy) : 'No Data'} </span>
                </div>

                <div key={'dst'}
                     style={{
                         display: 'flex',
                         justifyContent: 'space-between',
                         fontSize: '14px'
                     }}>Standing
                    Charge: <span style={{
                        marginLeft: '20px',
                        fontWeight: 700
                    }}>{formatter.format(((v.standingChargeValue) / 100) / dividedBy)}</span></div>

                {v.comparisonValue || v.comparisonValue === 0 ?
                    <div className={'compare-text'} style={{color: 'grey', fontSize: '13px'}}>
                        <div style={{margin: '10px 0'}} className={'divider'}/>
                        <p style={{
                            color: '#aaaaaa',
                            fontSize: '13px',
                            margin: '0px',
                            marginBottom: '5px',
                            fontWeight: 400
                        }}>Compared to...</p>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}
                             key={'uc'}>Usage: <span style={{
                            marginLeft: '20px',
                            fontWeight: 700
                        }}>
                                         {formatter.format(((v.comparisonValue) / 100) / dividedBy)}</span>
                        </div>
                        <div key={'dst'}
                             style={{
                                 display: 'flex',
                                 justifyContent: 'space-between'
                             }}>Standing
                            Charge: <span style={{
                                marginLeft: '20px',
                                fontWeight: 700
                            }}>{formatter.format(((v.standingChargeComparisonValue || 0) / 100) / dividedBy)}</span>
                        </div>
                    </div> : ''}

            </React.Fragment>
        )
    }
}

export default TariffBreakdown;
